import { useDispatch, useSelector } from 'react-redux';
import ButtonBack from '../../components/Button/ButtonBack';
import ButtonDataUpdate from '../../components/Button/ButtonDataUpdate';
import Loader from '../../components/Loader';
import Nav from '../../components/Nav';
import Section from '../../components/Layout/Section';

import Selector from '../../components/Selector';
import AdminDashboard from '../../components/AdminDashboard';

import { Header, Title, Main, Content } from '../../components/Layout/styles';

import ViewSelector from '../../components/ViewSelector';
import {
  getData,
  getBudgetScenarios,
  resetClicked,
  divisionSelected,
  dateChanged,
  periodChanged,
} from '../../state/reducers/dashboardSlice';

import ProfitAndLossDashboard from '../../components/ProfitAndLossDashboard/component';
import BalanceSheetDashboard from '../../components/BalanceSheetDashboard';

import { DASHBOARD_TITLES } from './constants';
import ButtonBackAdmin from '../../components/Button/ButtonBackAdmin';

import './style.css';
import CashflowDashboard from '../../components/CashflowDashboard/component';
import CashflowBudgetSelector from '../../components/CashflowDashboard/CashflowBudgetSelector/component';

function Dashboard() {
  const dispatch = useDispatch();
  const {
    isLoading,
    viewId,
    selectedDivision,
    selectedDashboard,
    selectedView,
  } = useSelector(state => state.dashboard);
  const { selectedClient, selectedAdminOption, theme } = useSelector(
    state => state.menu
  );

  return (
    <div className={`dashboard-container-${theme}`}>
      {isLoading && <Loader />}

      <Nav
        onDivisionChange={division => {
          dispatch(divisionSelected(division));
          dispatch(getBudgetScenarios());
        }}
      />
      <Main>
        <Content>
          <Header>
            {/* Header (Left): Title and UpdateButton */}
            <Section.Primary>
              {selectedDashboard !== 'admin' && (
                <Title>{DASHBOARD_TITLES[selectedDashboard]}</Title>
              )}
              {selectedDashboard === 'admin' && (
                <Title>{selectedAdminOption}</Title>
              )}

              {selectedDashboard !== 'admin' && (
                <ButtonDataUpdate
                  selectedClient={selectedClient}
                  onClick={() => {
                    dispatch(resetClicked());
                    dispatch(getData());
                  }}
                />
              )}
              {selectedDashboard === 'admin' && <ButtonBackAdmin />}
              {selectedDashboard === 'cashflow' && <CashflowBudgetSelector />}
              <ViewSelector
                show={viewId === 1 && selectedDashboard === 'profit-and-loss'}
              />
              <ButtonBack
                onClick={() => {
                  dispatch(resetClicked());
                  dispatch(getData());
                }}
                show={viewId !== 1 && selectedView !== 'table'}
              />
            </Section.Primary>
            {selectedDashboard !== 'admin' && (
              <Section.Secondary>
                <Selector
                  selectedDivision={selectedDivision}
                  onDateChange={date => {
                    dispatch(dateChanged(date));
                    if (selectedDashboard !== 'cashflow') {
                      dispatch(getData());
                    }
                  }}
                  onPeriodChange={period => {
                    dispatch(periodChanged(period));
                  }}
                />
              </Section.Secondary>
            )}
          </Header>
          {selectedDashboard === 'profit-and-loss' && (
            <ProfitAndLossDashboard />
          )}
          {selectedDashboard === 'balance-sheet' && <BalanceSheetDashboard />}
          {selectedDashboard === 'admin' && <AdminDashboard />}
          {selectedDashboard === 'cashflow' && <CashflowDashboard />}
        </Content>
      </Main>
    </div>
  );
}

export default Dashboard;
