import { useSelector } from 'react-redux';
import Tile from '../../Tile';
import './style.css';

function CashflowScorecard({ number, title, varia }) {
  const { theme } = useSelector(state => state.menu);
  return (
    <Tile height={0.165} className="cashflow-scorecard-tile">
      {number && (
        <div className={`cashflow-scorecard-number-${theme}`}>
          <span
            className={`cashflow-scorecard-sign-${theme}`}
            style={{
              visibility: parseFloat(number) < 0 ? 'visible' : 'hidden',
            }}
          >
            -
          </span>
          {`${Math.abs(Math.round(number / 1000)).toLocaleString('en-US')} k €`}
        </div>
      )}
      {varia && theme === 'dashwise' && (
        <div
          className={`cashflow-scorecard-label-${theme} cashflow-var-${theme}`}
          style={{
            backgroundColor: parseFloat(number) >= 0 ? '#C9F4AA' : '#FFAACF',
          }}
        >
          {title}
        </div>
      )}
      {varia && theme === 'finsights' && (
        <div
          className={`cashflow-scorecard-label-${theme} cashflow-var-${theme}`}
          style={{
            backgroundColor: parseFloat(number) >= 0 ? '#C9F4AA' : '#F5BFAB',
          }}
        >
          {title}
        </div>
      )}
      {!varia && (
        <div className={`cashflow-scorecard-label-${theme}`}>{title}</div>
      )}
    </Tile>
  );
}

export default CashflowScorecard;
