export const yAxisTicks = (graph, data, metadata) => {
  const revenue = [];
  const cogs = [];
  const opex = [];
  const nonOpex = [];
  const grossProfit = [];
  const grossProfitMargin = [];
  const ebitda = [];
  const ebitdaMargin = [];
  const netIncome = [];
  const netIncomeMargin = [];
  const revenueBudget = [];
  const cogsBudget = [];
  const opexBudget = [];
  const nonOpexBudget = [];
  const grossProfitBudget = [];
  const grossProfitMarginBudget = [];
  const ebitdaBudget = [];
  const ebitdaMarginBudget = [];
  const netIncomeBudget = [];
  const netIncomeMarginBudget = [];

  data.forEach(month => {
    revenue.push(month['Revenue']);
    cogs.push(month['COGS']);
    opex.push(month['OPEX']);
    nonOpex.push(month['Non-OPEX']);
    grossProfit.push(month['Gross Profit']);
    grossProfitMargin.push(month['Gross Profit Margin']);
    ebitda.push(month['EBITDA']);
    ebitdaMargin.push(month['EBITDA Margin']);
    netIncome.push(month['Net Income']);
    netIncomeMargin.push(month['Net Income Margin']);
    revenueBudget.push(month['Revenue Budget']);
    cogsBudget.push(month['COGS Budget']);
    opexBudget.push(month['OPEX Budget']);
    nonOpexBudget.push(month['Non-OPEX Budget']);
    grossProfitBudget.push(month['Gross Profit']);
    grossProfitMarginBudget.push(month['Gross Profit Margin Budget']);
    ebitdaBudget.push(month['EBITDA Budget']);
    ebitdaMarginBudget.push(month['EBITDA Margin Budget']);
    netIncomeBudget.push(month['Net Income Budget']);
    netIncomeMarginBudget.push(month['Net Income Margin Budget']);
  });

  const graphsMinMax = {
    Revenue: {
      min: Math.min.apply(Math, revenue),
      max: Math.max.apply(Math, revenue),
      type: 'bar',
    },
    COGS: {
      min: Math.min.apply(Math, cogs),
      max: Math.max.apply(Math, cogs),
      type: 'bar',
    },
    OPEX: {
      min: Math.min.apply(Math, opex),
      max: Math.max.apply(Math, opex),
      type: 'bar',
    },
    'Non-OPEX': {
      min: Math.min.apply(Math, nonOpex),
      max: Math.max.apply(Math, nonOpex),
      type: 'bar',
    },
    'Gross Profit': {
      min: Math.min.apply(Math, grossProfit),
      max: Math.max.apply(Math, grossProfit),
      type: 'line',
    },
    'Gross Profit Margin': {
      min: Math.min.apply(Math, grossProfitMargin),
      max: Math.max.apply(Math, grossProfitMargin),
      type: 'line',
    },
    EBITDA: {
      min: Math.min.apply(Math, ebitda),
      max: Math.max.apply(Math, ebitda),
      type: 'line',
    },
    'EBITDA Margin': {
      min: Math.min.apply(Math, ebitdaMargin),
      max: Math.max.apply(Math, ebitdaMargin),
      type: 'line',
    },
    'Net Income': {
      min: Math.min.apply(Math, netIncome),
      max: Math.max.apply(Math, netIncome),
      type: 'line',
    },
    'Net Income Margin': {
      min: Math.min.apply(Math, netIncomeMargin),
      max: Math.max.apply(Math, netIncomeMargin),
      type: 'line',
    },
    'Revenue Budget': {
      min: Math.min.apply(Math, revenueBudget),
      max: Math.max.apply(Math, revenueBudget),
      type: 'bar',
    },
    'COGS Budget': {
      min: Math.min.apply(Math, cogsBudget),
      max: Math.max.apply(Math, cogsBudget),
      type: 'bar',
    },
    'OPEX Budget': {
      min: Math.min.apply(Math, opexBudget),
      max: Math.max.apply(Math, opexBudget),
      type: 'bar',
    },
    'Non-OPEX Budget': {
      min: Math.min.apply(Math, nonOpexBudget),
      max: Math.max.apply(Math, nonOpexBudget),
      type: 'bar',
    },
    'Gross Profit Budget': {
      min: Math.min.apply(Math, grossProfitBudget),
      max: Math.max.apply(Math, grossProfitBudget),
      type: 'line',
    },
    'Gross Profit Margin Budget': {
      min: Math.min.apply(Math, grossProfitMarginBudget),
      max: Math.max.apply(Math, grossProfitMarginBudget),
      type: 'line',
    },
    'EBITDA Budget': {
      min: Math.min.apply(Math, ebitdaBudget),
      max: Math.max.apply(Math, ebitdaBudget),
      type: 'line',
    },
    'EBITDA Margin Budget': {
      min: Math.min.apply(Math, ebitdaMarginBudget),
      max: Math.max.apply(Math, ebitdaMarginBudget),
      type: 'line',
    },
    'Net Income Budget': {
      min: Math.min.apply(Math, netIncomeBudget),
      max: Math.max.apply(Math, netIncomeBudget),
      type: 'line',
    },
    'Net Income Margin Budget': {
      min: Math.min.apply(Math, netIncomeMarginBudget),
      max: Math.max.apply(Math, netIncomeMarginBudget),
      type: 'line',
    },
  };

  const selectedGraphs = [];
  const selectedGraphsTypes = [];
  metadata.forEach(graph => {
    if (graph['label']) {
      selectedGraphs.push(graph['label']);
      selectedGraphsTypes.push(graph['type']);
    }
  });

  const uniqueGraphs = Array.from(new Set(selectedGraphsTypes));

  if (uniqueGraphs.length === 1) {
    return [0, 'auto'];
  }

  const barGraphs = [];
  const lineGraphs = [];
  selectedGraphs.forEach(graph => {
    if (graphsMinMax[graph].type === 'bar') {
      barGraphs.push(graphsMinMax[graph].min);
      barGraphs.push(graphsMinMax[graph].max);
    } else {
      lineGraphs.push(graphsMinMax[graph].min);
      lineGraphs.push(graphsMinMax[graph].max);
    }
  });

  const barMin = Math.min.apply(Math, barGraphs);
  const barMax = Math.max.apply(Math, barGraphs);
  const lineMin = Math.min.apply(Math, lineGraphs);
  const lineMax = Math.max.apply(Math, lineGraphs);

  if (
    (barMin >= 0 && barMax >= 0 && lineMin >= 0 && lineMax >= 0) ||
    (barMin <= 0 && barMax <= 0 && lineMin <= 0 && lineMax <= 0)
  ) {
    return [0, 'auto'];
  } else {
    if (graph === 'bar') {
      const max = Math.max.apply(Math, [Math.abs(barMin), Math.abs(barMax)]);
      // Correction on max, number must be like 200000 and not 199999 or 200001
      const maxAsString = '' + Math.floor(max);
      let newMax = parseInt(maxAsString[0]) + 1;
      for (let i = 1; i < maxAsString.length; i++) {
        newMax = newMax * 10;
      }
      return [newMax * -1, newMax];
    } else {
      const max = Math.max.apply(Math, [Math.abs(lineMin), Math.abs(lineMax)]);
      const maxAsString = '' + Math.floor(max);
      let newMax = parseInt(maxAsString[0]) + 1;
      for (let i = 1; i < maxAsString.length; i++) {
        newMax = newMax * 10;
      }
      return [newMax * -1, newMax];
    }
  }
};
