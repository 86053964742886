import axios from 'axios';
import * as Api from './api';

const loggedin = localStorage.getItem('loggedin');

// Create the axios instance with barer token.
const axiosInstance = loggedin
  ? axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT,
      withCredentials: true,
    })
  : axios;

/**
 *
 * Axios interceptors are methods which are triggered before every axios request
 * and after every axios response.
 *
 * It is useful to check response status code for every response that is being received.
 *
 */

axiosInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      localStorage.setItem('loggedOutError', 'Unidentified User');
      window.location = '/login';
    } else if (error.response.status === 403) {
      localStorage.setItem('loggedOutError', 'Unauthorised Access');
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
