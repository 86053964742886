import { useDispatch, useSelector } from 'react-redux';

import DashboardChart from '../DashboardChart';
import DashboardTable from '../DashboardTable';
import DeepdivePanel from '../DeepdivePanel';
import Section from '../Layout/Section';
import { filterDataForPeriod } from '../../views/Dashboard/utils';

import { getData, analyseClicked } from '../../state/reducers/dashboardSlice';
import { ROOT_NODES, COLUMN_ORDER } from './constants';

function BalanceSheetDashboard() {
  const dispatch = useDispatch();
  const {
    isLoading,
    selectedView,
    selectedDate,
    selectedPeriod,
    selectedGroup,
    metadata,
    activeMetrics,
    viewId,
    highlightedMetric,
    metricKey,
    data,
  } = useSelector(state => state.dashboard);

  const handleAnalyseClick = node => {
    dispatch(analyseClicked(node));
    dispatch(getData());
  };

  let metadataActiveMetrics;
  if (selectedView === 'graphs' && !isLoading) {
    metadataActiveMetrics = metadata[1].map((chart, index) => {
      return activeMetrics.includes(index) ? chart : {};
    });
  }

  return (
    <>
      {selectedView === 'graphs' && !isLoading && (
        <>
          <Section.Primary>
            <DashboardChart
              data={data}
              metadata={metadataActiveMetrics}
              viewId={viewId}
              highlightedMetric={highlightedMetric}
            />
          </Section.Primary>
          <Section.Secondary>
            <DeepdivePanel
              data={filterDataForPeriod(data, selectedDate, selectedPeriod)}
              metadata={metadata[1]}
              metricKey={metricKey}
              activeMetrics={activeMetrics}
              viewId={viewId}
              groupBy={selectedGroup}
              onGroupChange={group => {
                dispatch(groupChanged(group));
                dispatch(getData());
              }}
              onOrderChange={order => {
                dispatch(orderChanged(order));
                dispatch(getData());
              }}
            />
          </Section.Secondary>
        </>
      )}
      {selectedView === 'table' && !isLoading && (
        <DashboardTable
          onAnalyseClick={handleAnalyseClick}
          data={data}
          analysableNodes={metadata.map(graph => graph.label)}
          rootNodes={ROOT_NODES}
          columnOrder={COLUMN_ORDER}
        />
      )}
    </>
  );
}

export default BalanceSheetDashboard;
