import { useSelector } from 'react-redux';
import './style.css';

function Checkbox({ onChange, item, checked, logo }) {
  const { theme } = useSelector(state => state.menu);
  return (
    <>
      <label className="checkbox-container">
        {logo && (
          <img src={logo} width={18} height={18} className="checkbox-logo" />
        )}
        {item}
        <input type="checkbox" onChange={onChange} checked={checked} />

        <span className={`checkbox-checkmark-${theme}`}></span>
      </label>
    </>
  );
}

export default Checkbox;
