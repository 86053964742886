import axios from './axiosInstance';
import {
  ApiBaseUrl,
  AuthGooglePath,
  AuthMicrosoftPath,
  DashboardsPath,
  UserInfoPath,
  ClientPath,
  LogosPath,
  UpdateDataPath,
  UpdatePiplinesPath,
  PeriodsPath,
  BudgetsPath,
  LoggedInPath,
  logOutPath,
  AdminClientsPath,
  AdminEditClientPath,
  AdminAddClientPath,
  AdminDeleteClientPath,
  AdminUsersPath,
  AdminUserRolesPath,
  AdminAddUserPath,
  AdminDeleteUserPath,
  AdminEditUserPath,
  AdminAddUserClientsPath,
  CashflowPath,
  AdminDivisionsPath,
  AdminAddUserDivisionsPath,
} from './constants';

// -------------------------- General --------------------------

function apiGet(path) {
  return axios.get(ApiBaseUrl + path, {
    withCredentials: true,
  });
}

function apiPost(path) {
  return axios.post(ApiBaseUrl + path, {
    withCredentials: true,
  });
}

// ------------------------- Login API -------------------------

export function loginGoogle() {
  return apiGet(AuthGooglePath);
}

export function loginMicrosoft() {
  return apiGet(AuthMicrosoftPath);
}

export function loginCallback() {
  // check if there is a query string with 'code' in it
  const queryString = window.location.search;
  if (!queryString.includes('?code=')) {
    window.location = '/login';
  }
  // determine API to call based on query string
  const callbackPath = queryString.includes('googleapis')
    ? AuthGooglePath
    : AuthMicrosoftPath;
  // find code in query string and include in callback URL
  const code = queryString.split('?code=')[1];
  return apiGet(callbackPath + '/callback?code=' + code);
}

// ----------------------- Auth/session API -----------------------
export function logOut() {
  return apiGet(logOutPath);
}
// ----------------------- Dashboard API -----------------------

export function getDashboard(
  balanceType,
  division,
  metric,
  date,
  period,
  group,
  order,
  view,
  budget,
  budgetvar,
  theme
) {
  const queryParams = {
    division,
    metric,
    date,
    period,
    group,
    order,
    budget,
    budgetvar,
    theme,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${DashboardsPath}/${view}/${balanceType}?${queryString}`;
  return apiGet(url);
}

export function getScenarios(division) {
  const queryParams = { division };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${BudgetsPath}?${queryString}`;
  return apiGet(url);
}

export function getSelectorOptions(division) {
  const queryParams = { division };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${PeriodsPath}?${queryString}`;
  return apiGet(url);
}

export function updateClientData(ew_client_id) {
  const queryParams = { ew_client_id };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${UpdateDataPath}?${queryString}`;
  return apiGet(url);
}

export function updatePipelines(ew_client_id) {
  const queryParams = { ew_client_id };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${UpdatePiplinesPath}?${queryString}`;
  return apiGet(url);
}

export function getUser() {
  return apiGet(UserInfoPath);
}

export function getClients() {
  return apiGet(ClientPath);
}

export function getLogos() {
  return apiGet(LogosPath);
}

// ----------------------- Admin API -----------------------

export function getClientsAdmin() {
  return apiGet(AdminClientsPath);
}

export function getDivisionsAdmin() {
  return apiGet(AdminDivisionsPath);
}

export function editClient(ew_client_id, name, logo, exact_username) {
  const queryParams = {
    ew_client_id,
    name,
    logo,
    exact_username,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${AdminEditClientPath}?${queryString}`;
  return apiPost(url);
}

export function addClient(name, logo, exact_username) {
  const queryParams = {
    name,
    logo,
    exact_username,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${AdminAddClientPath}?${queryString}`;
  return apiPost(url);
}

export function deleteClient(ew_client_id) {
  const queryParams = {
    ew_client_id,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${AdminDeleteClientPath}?${queryString}`;
  return apiPost(url);
}

export function getUsersAdmin() {
  return apiGet(AdminUsersPath);
}

export function getUserRoles() {
  return apiGet(AdminUserRolesPath);
}

export function addUser(name, email, userrole) {
  const queryParams = {
    name,
    email,
    userrole,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${AdminAddUserPath}?${queryString}`;
  return apiPost(url);
}

export function editUser(id, name, email, userrole) {
  const queryParams = {
    id,
    name,
    email,
    userrole,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${AdminEditUserPath}?${queryString}`;
  return apiPost(url);
}

export function deleteUser(id) {
  const queryParams = {
    id,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${AdminDeleteUserPath}?${queryString}`;
  return apiPost(url);
}

export function editUserClients(id, clients) {
  const queryParams = {
    id,
    clients,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${AdminAddUserClientsPath}?${queryString}`;
  return apiPost(url);
}

export function editUserDivisions(id, divisions) {
  const queryParams = {
    id,
    divisions,
  };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${AdminAddUserDivisionsPath}?${queryString}`;
  return apiPost(url);
}

// ----------------------- Cashflow API -----------------------

export function getCashFlow(division, date, period, budget) {
  const queryParams = { division, date, period, budget };
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `${CashflowPath}?${queryString}`;
  return apiGet(url);
}
