import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  periodIndexSelected,
  periodSelected,
  optionsUpdated,
  menuClicked,
} from '../../state/reducers/menuSlice';
import Arrow from '../Arrow';
import Button from '../Button';
import Tile from '../Tile';
import CalenderIcon from '../../static/svg/calender.svg';
import CalenderIconFn from '../../static/svg/calender-fn.svg';

import { dropdownOptions, formatters } from './constants';
import './style.css';

function Selector({ onDateChange, onPeriodChange } = props) {
  const dispatch = useDispatch();
  const { periodsMenu, options, selectedPeriodIndex, isDropdownOpen, theme } =
    useSelector(state => state.menu);
  const { selectedView, selectedPeriod, selectedDashboard } = useSelector(
    state => state.dashboard
  );

  useEffect(() => {}, [selectedPeriod]);

  const changeSelection = increment => {
    dispatch(menuClicked());
    // check if options is empty or new index is out of bounds
    const n = options.length;
    const newIndex = selectedPeriodIndex + increment;
    if (n == 0 || newIndex < 0 || newIndex > n - 1) {
      return;
    }

    dispatch(periodIndexSelected(newIndex));
    onDateChange(options[newIndex]);
  };

  const handleClickPeriodDropdown = () => {
    dispatch(menuClicked('isDropdownOpen'));
  };

  const handleClickPeriodOption = selectedPeriod => {
    const { options, defaultIndex } = periodsMenu[selectedPeriod];
    dispatch(periodIndexSelected(defaultIndex));
    dispatch(periodSelected(selectedPeriod));
    dispatch(optionsUpdated(options));
    onPeriodChange(selectedPeriod);
    onDateChange(options[defaultIndex]);
    dispatch(menuClicked());
  };

  // check if options is empty, if yes, return empty selectedDate

  const selectedDate = options.length > 0 ? options[selectedPeriodIndex] : '';
  const direction = isDropdownOpen ? 'up' : 'down';
  const formatDate = formatters[selectedPeriod];
  return (
    <>
      <Tile className={theme === 'finsights' && 'tile-content-fn-selector'}>
        <Button
          className={
            theme === 'dashwise'
              ? 'selector-arrow-container'
              : 'selector-arrow-container-left'
          }
          onClick={() => changeSelection(-1)}
        >
          <Arrow
            shape={theme === 'dashwise' ? 'arrowhead' : 'arrowhead-black'}
            direction={'left'}
          />
        </Button>
        <div className={`selector-month-container-${theme}`}>
          {selectedDashboard !== 'cashflow' && (
            <img src={theme === 'dashwise' ? CalenderIcon : CalenderIconFn} />
          )}
          <div className={`selector-month-wrapper-${theme}`}>
            {`${formatDate(selectedDate)} `}{' '}
            {selectedPeriod == 'ytd' &&
              selectedDashboard !== 'cashflow' &&
              'YTD'}
            {selectedPeriod == 'rf' && 'RF'}
            <span></span>
            {selectedDashboard !== 'cashflow' && (
              <Button onClick={handleClickPeriodDropdown}>
                <Arrow
                  shape={
                    theme === 'dashwise'
                      ? 'arrowhead'
                      : 'arrowhead-black-margin'
                  }
                  direction={direction}
                />
              </Button>
            )}
          </div>
        </div>
        <Button
          className={
            theme === 'dashwise'
              ? 'selector-arrow-container'
              : 'selector-arrow-container-right'
          }
          onClick={() => changeSelection(+1)}
        >
          <Arrow
            shape={theme === 'dashwise' ? 'arrowhead' : 'arrowhead-black'}
            direction={'right'}
          />
        </Button>
      </Tile>
      {isDropdownOpen && (
        <div className={`selector-dropdown-container-${theme}`}>
          {dropdownOptions.map(option => {
            return (
              <Button
                key={option.key}
                className={
                  (option.key == 'rf' || option.key == 'mulyears') &&
                  selectedDashboard == 'balance-sheet'
                    ? 'selector-dropdown-item-not-active'
                    : `selector-dropdown-item-active-${theme}`
                }
                onClick={
                  (option.key == 'rf' || option.key == 'mulyears') &&
                  selectedDashboard == 'balance-sheet'
                    ? null
                    : () => handleClickPeriodOption(option.key)
                }
              >
                {option.label}
              </Button>
            );
          })}
        </div>
      )}
    </>
  );
}

export default Selector;
