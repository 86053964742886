import { useSelector } from 'react-redux';
import {
  Bar,
  Cell,
  Line,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';

import { formatDateToShortMonth } from '../../../utils/formatters';

import Tile from '../../Tile';
import CashflowTooltip from '../CashflowTooltip';
import * as constants from './constants';

function CashflowChart({ data }) {
  const { theme } = useSelector(state => state.menu);
  return (
    <>
      {theme === 'dashwise' && (
        <Tile className="tile-content-admin">
          {data.length > 0 && (
            <div style={{ height: '100%', padding: '20px' }}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  data={data[0]}
                  {...constants.COMPOSED_CHART_PARAMETERS}
                >
                  <XAxis
                    xAxisId={0}
                    padding={{ left: 10 }}
                    {...constants.X_AXIS_PARAMETERS}
                    tickFormatter={formatDateToShortMonth}
                  />
                  <YAxis
                    {...constants.Y_AXIS_PARAMETERS}
                    domain={[0, 'auto']}
                  />
                  <ReferenceLine {...constants.REFERENCE_LINE_PARAMETERS} />
                  <Tooltip
                    content={<CashflowTooltip cutoff={data[2]['cutoff']} />}
                    {...constants.TOOLTIP_PARAMETERS}
                  />
                  {data[1].map((chart, index) => {
                    return (
                      chart.type === 'bar' && (
                        <>
                          <Bar
                            dataKey={chart.label}
                            radius={[10, 10, 10, 10]}
                            key={index}
                            barSize={20}
                          >
                            {data[0].map((entry, index) => {
                              const color =
                                entry.mutation > 0 ? '#C9F4AA' : '#FFAACF';
                              return (
                                <Cell
                                  opacity={
                                    index >= data[2]['cutoff'].toString()
                                      ? 0.5
                                      : 1.0
                                  }
                                  fill={color}
                                  key={index}
                                />
                              );
                            })}
                          </Bar>
                        </>
                      )
                    );
                  })}
                  {data[1].map((chart, index) => {
                    return (
                      chart.type === 'line' && (
                        <>
                          <defs>
                            <linearGradient
                              id={`colorUv${index}`}
                              x1="0%"
                              y1="0"
                              x2="100%"
                              y2="0"
                            >
                              <stop offset="0%" stopColor={chart.color} />
                              <stop
                                offset={`${
                                  ((data[2]['cutoff'] + 1) / 12) * 100 - 3
                                }%`}
                                stopColor={chart.color}
                              />
                              <stop
                                offset={`${
                                  ((data[2]['cutoff'] + 1) / 12) * 100 - 3
                                }%`}
                                stopColor={
                                  chart.color.substring(
                                    0,
                                    chart.color.length - 2
                                  ) + '0.4)'
                                }
                              />
                              <stop
                                offset={`${100}%`}
                                stopColor={
                                  chart.color.substring(
                                    0,
                                    chart.color.length - 2
                                  ) + '0.4)'
                                }
                              />
                            </linearGradient>
                          </defs>
                          <Line
                            type="monotone"
                            dataKey={chart.label}
                            stroke={
                              parseInt(data[2]['cutoff']) === 1
                                ? chart.color
                                : `url(#colorUv${index})`
                            }
                            key={index}
                            {...constants.LINE_PARAMETERS}
                          />
                        </>
                      )
                    );
                  })}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          )}
        </Tile>
      )}
      {theme === 'finsights' && (
        <Tile className="tile-content-admin">
          {data.length > 0 && (
            <div style={{ height: '100%', padding: '20px' }}>
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  data={data[0]}
                  {...constants.COMPOSED_CHART_PARAMETERS}
                >
                  <XAxis
                    xAxisId={0}
                    padding={{ left: 10 }}
                    {...constants.X_AXIS_PARAMETERS}
                    tickFormatter={formatDateToShortMonth}
                  />
                  <YAxis
                    {...constants.Y_AXIS_PARAMETERS}
                    domain={[0, 'auto']}
                  />
                  <ReferenceLine {...constants.REFERENCE_LINE_PARAMETERS} />
                  <Tooltip
                    content={<CashflowTooltip cutoff={data[2]['cutoff']} />}
                    {...constants.TOOLTIP_PARAMETERS}
                  />
                  {data[1].map((chart, index) => {
                    return (
                      chart.type === 'bar' && (
                        <>
                          <Bar
                            dataKey={chart.label}
                            radius={[10, 10, 10, 10]}
                            key={index}
                            barSize={20}
                          >
                            {data[0].map((entry, index) => {
                              const color =
                                entry.mutation > 0 ? '#C9F4AA' : '#F5BFAB';
                              return (
                                <Cell
                                  opacity={
                                    index >= data[2]['cutoff'].toString()
                                      ? 0.5
                                      : 1.0
                                  }
                                  fill={color}
                                  key={index}
                                />
                              );
                            })}
                          </Bar>
                        </>
                      )
                    );
                  })}
                  {data[1].map((chart, index) => {
                    return (
                      chart.type === 'line' && (
                        <>
                          <defs>
                            <linearGradient
                              id={`colorUv${index}`}
                              x1="0%"
                              y1="0"
                              x2="100%"
                              y2="0"
                            >
                              <stop
                                offset="0%"
                                stopColor={'rgba(70, 70, 70, 1)'}
                              />
                              <stop
                                offset={`${
                                  ((data[2]['cutoff'] + 1) / 12) * 100 - 3
                                }%`}
                                stopColor={'rgba(70, 70, 70, 1)'}
                              />
                              <stop
                                offset={`${
                                  ((data[2]['cutoff'] + 1) / 12) * 100 - 3
                                }%`}
                                stopColor={
                                  'rgba(70, 70, 70, 1)'.substring(
                                    0,
                                    'rgba(70, 70, 70, 1)'.length - 2
                                  ) + '0.4)'
                                }
                              />
                              <stop
                                offset={`${100}%`}
                                stopColor={
                                  'rgba(70, 70, 70, 1)'.substring(
                                    0,
                                    'rgba(70, 70, 70, 1)'.length - 2
                                  ) + '0.4)'
                                }
                              />
                            </linearGradient>
                          </defs>
                          <Line
                            type="monotone"
                            dataKey={chart.label}
                            stroke={
                              parseInt(data[2]['cutoff']) === 1
                                ? 'rgba(70, 70, 70, 1)'
                                : `url(#colorUv${index})`
                            }
                            key={index}
                            {...constants.LINE_PARAMETERS}
                          />
                        </>
                      )
                    );
                  })}
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          )}
        </Tile>
      )}
    </>
  );
}

export default CashflowChart;
