import { createSlice, createAsyncThunk, compose } from '@reduxjs/toolkit';
import * as Api from '../../utils/api';

// The default state.
const initialState = {
  data: [], // contains the graph data for the selected options (e.g. period, group, order etc.) // contains the table graph data for the selected options (e.g. period, group, order etc.)
  metadata: [], // contains the graph metadata (e.g. chart type, label etc.)
  metricKey: '', // selected metric key (in metric deepdive)
  viewId: 1, // 1: overview | 2: metric deepdive | 3: breakdown
  selectedDivision: null, // selected EW Client ID
  selectedDate: `${new Date().getFullYear()}-01-01`, // selected date (e.g. '2023-01-01')
  selectedPeriod: 'year', // selected period (e.g. year, month, ytd etc.)
  selectedGroup: '', // selected grouping method (e.g. by account, ledger etc.)
  selectedOrder: '', // selected order (e.g. ascending, descending etc.)
  selectedView: 'graphs', // selected view ('graphs' | 'table')
  activeMetrics: [0, 3, 4, 7, 9, 12], // indices of metrics that are displayed on scorecards / graph
  highlightedMetric: null, // index of metric that is highlighted on scorecards / graph
  isLoading: true, // true if data is being fetched from the server
  isMenuOptionsLoading: true,
  errorMessage: '',
  selectedDashboard: 'profit-and-loss', // sets the dashboard type, for getting the right data
  selectedViewSelector: '', // the selecter view "Graph/Data" or "Select Budget"
  selectedDeepdiveMetric: '',
  budget: '', // the selected budget/scenario
  budgetVar: '', // the variable budget/scenario for the re-forecast
  totals: [],
  columnsToHide: [],
  marginAmount: [
    'Revenue',
    'COGS',
    'Gross Profit Margin',
    'OPEX',
    'EBITDA Margin',
    'Non-OPEX',
    'Net Income Margin',
    'Revenue Budget',
    'COGS Budget',
    'Gross Profit Margin Budget',
    'OPEX Budget',
    'EBITDA Margin Budget',
    'Non-OPEX Budget',
    'Net Income Margin Budget',
  ], // selects the graphs that are shown, default is the amount
  views: [
    {
      view: 'Graph/Table',
      options: ['Graph', 'Table'],
    },
  ], // the different options for view selectior pull down button
  symbolLookup: {
    Revenue: '€',
    COGS: '€',
    'Gross Profit': '€',
    'Gross Profit Margin': '%',
    OPEX: '€',
    EBITDA: '€',
    'EBITDA Margin': '%',
    'Non-OPEX': '€',
    'Net Income': '€',
    'Net Income Margin': '%',
  },
  // Checking if there is a need for the % symbol or the pp symbol
  ppSymbol: ['Gross Profit Margin', 'EBITDA Margin', 'Net Income Margin'],
  styleLookup: {
    Revenue: {
      weight: '400',
      style: 'normal',
    },
    'Gross Profit': {
      weight: '700',
      style: 'normal',
    },
    'Gross Profit Margin': {
      weight: '500',
      style: 'italic',
    },
    COGS: {
      weight: '400',
      style: 'normal',
    },
    OPEX: {
      weight: '400',
      style: 'normal',
    },
    EBITDA: {
      weight: '700',
      style: 'normal',
    },
    'EBITDA Margin': {
      weight: '500',
      style: 'italic',
    },
    'Non-OPEX': {
      weight: '400',
      style: 'normal',
    },
    'Net Income': {
      weight: '700',
      style: 'normal',
    },
    'Net Income Margin': {
      weight: '500',
      style: 'italic',
    },
    'Unprocessed Result': {
      weight: '400',
      style: 'italic',
    },
  },
};

/**
 * Redux middleware for handling asynchronous functions/requests. In this case for fetching the Profit and Loss data
 * createAsyncThunk create three action creators: pending, fullfilled and rejected
 * These action creators are connected to reducers in the extraReducers. See below.
 */
export const getData = createAsyncThunk(
  'dashboard/getData',
  async (args, { getState }) => {
    const { dashboard, menu } = getState();
    const response = await Api.getDashboard(
      dashboard.selectedDashboard,
      dashboard.selectedDivision,
      dashboard.selectedDeepdiveMetric,
      dashboard.selectedDate,
      dashboard.selectedPeriod,
      dashboard.selectedGroup,
      dashboard.selectedOrder,
      dashboard.selectedView,
      dashboard.budget,
      dashboard.budgetVar,
      menu.theme
    );

    return response.data;
  }
);

export const getBudgetScenarios = createAsyncThunk(
  'dashboard/getBudgetScenarios',
  async (args, { getState }) => {
    const { dashboard } = getState();

    const response = await Api.getScenarios(dashboard.selectedDivision);

    return response.data;
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    dashboardSelected(state, { payload }) {
      if (payload === 0) {
        // TODO needs better solution but for prevents trying to reload
        if (state.selectedDashboard !== 'profit-and-loss') {
          state.metricKey = '';
          state.isLoading = true;
          state.data = [];
          state.activeMetrics = [0, 3, 4, 7, 9, 12];
          state.selectedGroup = '';
          state.selectedOrder = '';
          state.viewId = 1;
          state.selectedDashboard = 'profit-and-loss';
          state.selectedView = 'graphs';
        }
      }

      if (payload === 1) {
        // TODO needs better solution but for prevents trying to reload
        if (state.selectedDashboard !== 'balance-sheet') {
          state.metricKey = '';
          state.isLoading = true;
          state.data = {};
          state.selectedDashboard = 'balance-sheet';
          state.selectedView = 'table';
        }
      }

      if (payload === 2) {
        state.selectedDashboard = 'cashflow';
      }

      if (payload === 3) {
        state.selectedDashboard = 'admin';
      }
    },
    budgetSet: (state, { payload }) => {
      state.budget = payload;
    },
    budgetVarSet: (state, { payload }) => {
      state.budgetVar = payload;
    },
    // the reducer to update the state with the new selected division
    divisionSelected: (state, { payload }) => {
      state.isLoading = true;
      state.selectedDivision = payload;

      localStorage.setItem('selectedDivision', payload);
    },
    // the reducer to update the state when the reset is clicked
    resetClicked: state => {
      state.data = [];
      state.metricKey = initialState.metricKey;
      state.selectedDeepdiveMetric = initialState.selectedDeepdiveMetric;
      state.viewId = initialState.viewId;
      state.activeMetrics = initialState.activeMetrics;
      state.highlightedMetric = initialState.highlightedMetric;
      state.selectedGroup = initialState.selectedGroup;
      state.selectedOrder = initialState.selectedOrder;

      if (state.selectedDashboard === 'balance-sheet') {
        state.selectedView = 'table';
      }
    },
    // the reducer to update the state with the new selected view (table or graph)
    viewSwitched: (state, { payload }) => {
      if (state.selectedView === 'table') {
        state.data = [];
      }

      state.selectedView = payload;
    },
    // the reducer to update the state when the analyze button is clicked
    analyseClicked: (state, { payload }) => {
      if (state.selectedView === 'table') {
        state.data = [];
      }
      // state.isLoading = true;
      state.activeMetrics = [payload];
      // state.metricKey = state.metadata[payload].label;
      state.metricKey = state.selectedDeepdiveMetric;
      state.viewId = 2;
      state.selectedView = 'graphs';
    },
    // the reducer to update the state with the new selected date
    dateChanged: (state, { payload }) => {
      state.selectedDate = payload;
    },
    // the reducer to update the state with the new selected period (year, month, year to date)
    periodChanged: (state, { payload }) => {
      state.selectedPeriod = payload;
    },
    // the reducer to update the state with the new selected group
    groupChanged: (state, { payload }) => {
      state.highlightedMetric = null;
      if (!payload || state.selectedGroup === '') {
        state.data = [];
      }
      state.selectedGroup = payload;
    },
    // the reducer to update the state with the new selected order (descending, ascending, none)
    orderChanged: (state, { payload }) => {
      state.selectedOrder = payload;
    },
    // the reducer to update the state with if a scorecard is hovered over
    scorecardHovered: (state, { payload }) => {
      state.highlightedMetric = payload;
    },
    // the reducer to set the isLoading state
    isLoadingSet: (state, { payload }) => {
      state.isLoading = payload;
    },
    // the reducer to update the state with the new selected scorecard (to enable it or disable it)
    scorecardClicked: (state, { payload }) => {
      const marginOrAmount = state.metadata.filter(item => {
        if (state.marginAmount.includes(item.label)) {
          return item;
        }
      });
      const metric = marginOrAmount[payload].label;

      const budgetIndex = marginOrAmount.findIndex(
        item => item.label === `${metric} Budget`
      );

      const newList = state.activeMetrics;

      if (newList.includes(payload)) {
        newList.splice(newList.indexOf(payload), 1);
        newList.splice(newList.indexOf(budgetIndex), 1);
      } else {
        newList.push(payload);
        newList.push(budgetIndex);
      }
      newList.sort();
      state.activeMetrics = newList;
    },
    viewSelectorViewSelected: (state, { payload }) => {
      if (state.selectedViewSelector === payload) {
        state.selectedViewSelector = '';
      } else {
        state.selectedViewSelector = payload;
      }
    },
    viewSelectorOptionSelected: (state, { payload }) => {
      if (state.budget === payload) {
        state.budget = '';
      } else {
        state.budget = payload;
      }
    },
    viewSelectorOptionSelectedVar: (state, { payload }) => {
      if (state.budgetVar === payload) {
        state.budgetVar = '';
      } else {
        state.budgetVar = payload;
      }
    },
    metricSelected: (state, { payload }) => {
      state.selectedDeepdiveMetric = payload;
    },
    monthColumnsToggled: (state, { payload }) => {
      if (state.columnsToHide.length === 0) {
        state.columnsToHide = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
          'Jan, RF',
          'Feb, RF',
          'Mar, RF',
          'Apr, RF',
          'May, RF',
          'Jun, RF',
          'Jul, RF',
          'Aug, RF',
          'Sep, RF',
          'Oct, RF',
          'Nov, RF',
          'Dec, RF',
        ];
      } else {
        state.columnsToHide = [];
      }
    },
    marginAmountSelected: (state, { payload }) => {
      if (payload) {
        state.marginAmount = [
          'Revenue',
          'COGS',
          'Gross Profit Margin',
          'OPEX',
          'EBITDA Margin',
          'Non-OPEX',
          'Net Income Margin',
          'Revenue Budget',
          'COGS Budget',
          'Gross Profit Margin Budget',
          'OPEX Budget',
          'EBITDA Margin Budget',
          'Non-OPEX Budget',
          'Net Income Margin Budget',
        ];
      } else {
        state.marginAmount = [
          'Revenue',
          'COGS',
          'Gross Profit',
          'OPEX',
          'EBITDA',
          'Non-OPEX',
          'Net Income',
          'Revenue Budget',
          'COGS Budget',
          'Gross Profit Budget',
          'OPEX Budget',
          'EBITDA Budget',
          'Non-OPEX Budget',
          'Net Income Budget',
        ];
      }
    },
  },
  extraReducers: builder => {
    builder
      // the reducer for the pending state when fetching the data.
      .addCase(getData.pending, state => {
        state.isLoading = true;
      })
      // the reducer for the pending state when the data is fetched. The state get updated with its results
      .addCase(getData.fulfilled, (state, { payload }) => {
        // get data
        const { data, metadata, totals } = payload;
        state.isLoading = false;
        state.data = data;
        if (totals) {
          state.totals = totals;
        }

        // update metadata
        // update metadata in any case
        state.metadata = metadata;

        // case 1: overview
        if (!state.metricKey) {
          state.viewId = 1;
        }
        // case 2: deepdive
        else if (!state.selectedGroup) {
          state.viewId = 2;
          if (state.selectedDashboard === 'profit-and-loss') {
            state.activeMetrics = [
              metadata.findIndex(chart => chart.label == state.metricKey),
            ];
          }

          if (state.selectedDashboard === 'balance-sheet') {
            state.activeMetrics = [
              metadata[1].findIndex(chart => chart.label == state.metricKey),
            ];
          }
        }
        // case 3: breakdown
        else if (state.selectedGroup) {
          state.viewId = 3;
          state.activeMetrics = [...Array(metadata.length).keys()];
        }
      })
      // the reducer when the getData call fails. The state.errormessage gets updated for futher use in the application
      .addCase(getData.rejected, (state, action) => {
        state.data = null;
        state.isLoading = false;
        state.errorMessage = action.error.message || '';
      })
      // the reducer for the pending state when fetching the view options.
      .addCase(getBudgetScenarios.pending, state => {
        state.isMenuOptionsLoading = true;
      })
      // the reducer for the pending state when the data is fetched. The state get updated with its results
      .addCase(getBudgetScenarios.fulfilled, (state, { payload }) => {
        state.isMenuOptionsLoading = false;
        const { data } = payload;
        let options;
        if (data.length > 0) {
          options = [
            {
              view: 'Select Budget',
              options: data.map(item => item.budgetscenariodescription),
            },
            {
              view: 'Re-Forecast Budget',
              options: data.map(item => `${item.budgetscenariodescription}`),
            },
            {
              view: 'Show Margins',
              options: ['Margin', 'Amount'],
            },
            {
              view: 'Hide Month Columns',
              options: [],
            },
          ];
        } else {
          options = [
            {
              view: 'Select Budget',
              options: ['No Budget'],
            },
            {
              view: 'Re-Forecast Budget',
              options: ['No Budget'],
            },
            {
              view: 'Show Margins',
              options: ['Margin', 'Amount'],
            },
            {
              view: 'Hide Month Columns',
              options: [],
            },
          ];
        }
        const oldState = state.views[0];
        state.views = [oldState, ...options];
      })
      // the reducer when the getBudgetScenarios call fails. The state.errormessage gets updated for futher use in the application
      .addCase(getBudgetScenarios.rejected, (state, action) => {
        state.data = null;
        state.isLoading = false;
        state.errorMessage = action.error.message || '';
      });
  },
});

export const {
  dashboardSelected,
  budgetSet,
  budgetVarSet,
  divisionSelected,
  resetClicked,
  viewSwitched,
  analyseClicked,
  scorecardHovered,
  scorecardClicked,
  dateChanged,
  periodChanged,
  groupChanged,
  orderChanged,
  viewSelectorViewSelected,
  viewSelectorOptionSelected,
  viewSelectorOptionSelectedVar,
  isLoadingSet,
  metricSelected,
  monthColumnsToggled,
  marginAmountSelected,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
