export const objectDepth = o =>
  Object(o) === o ? 1 + Math.max(-1, ...Object.values(o).map(objectDepth)) : 0;

export const getNodes = data => {
  const nodes = [];
  Object.keys(data).forEach(key => {
    const node = {};
    node.name = key;
    node.values = [];
    node.data = {};
    node.depth = 0;

    const items = Object.keys(data[key]);
    if (items.length > 0) {
      items.forEach(item => {
        if (objectDepth(data[key][item]) === 0) {
          const newItem = {
            key: item,
            value: data[key][item],
          };
          node.values.push(newItem);
        } else {
          node.depth = objectDepth(data[key][item]);
          node.data = data[key][item];
        }
      });
    }

    nodes.push(node);
  });

  return nodes;
};

export const getRootNodes = (data, nodes) => {
  const rootNodes = [];
  let keys;
  if (nodes) {
    keys = nodes;
  } else {
    keys = Object.keys(data);
  }
  keys.forEach(key => {
    const rootNode = {};
    rootNode.name = key;
    rootNode.values = [];
    rootNode.data = {};
    rootNode.depth = 0;

    const items = Object.keys(data[key]);
    if (items.length > 0) {
      items.forEach(item => {
        if (objectDepth(data[key][item]) === 0) {
          const newItem = {
            key: item,
            value: data[key][item],
          };
          rootNode.values.push(newItem);
        } else {
          rootNode.depth = objectDepth(data[key][item]);
          rootNode.data = data[key][item];
        }
      });
    }

    rootNodes.push([rootNode]);
  });

  return rootNodes;
};

// set columns
export const setColumns = values => {
  let columns;
  if (values.length > 3) {
    columns = 'minmax(26rem, 26rem)';
  } else {
    columns = 'minmax(36rem, 36rem)';
  }

  for (let i = 0; i < values.length; i++) {
    columns = columns + 'auto ';
  }

  return columns;
};

const isobject = x => {
  return Object.prototype.toString.call(x) === '[object Object]';
};

const getkeys = (obj, prefix) => {
  const keys = Object.keys(obj);
  prefix = prefix ? prefix + '.' : '';
  return keys.reduce(function (result, key) {
    if (isobject(obj[key])) {
      result = result.concat(getkeys(obj[key], prefix + key));
    } else {
      result.push((prefix + key).split('.'));
    }
    return result;
  }, []);
};

export const getPathLengths = data => {
  const branchLengths = {};
  const rootNodes = Object.keys(data);
  const allPaths = getkeys(data);
  rootNodes.forEach(node => {
    const path = [];
    allPaths.forEach(arr => {
      if (arr[0] === node) {
        arr.forEach(item => {
          if (!path.includes(item)) {
            path.push(item);
          }
        });
      }
    });
    branchLengths[node] = path.length;
  });
  return branchLengths;
};

export const sortObj = (obj, sortList) => {
  let result = obj.sort((a, b) => {
    return sortList.indexOf(a.key) - sortList.indexOf(b.key);
  });
  return result;
};
