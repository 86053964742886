import { useState } from 'react';
import { useSelector } from 'react-redux';
import Tile from '../../Tile';
import Button from '../../Button';
import Arrow from '../../Arrow';
import TrendIcon from '../../../static/svg/trend.svg';
import TrendIconFn from '../../../static/svg/trend-fn.svg';

import './style.css';
import CashflowBudgetSelectorOption from './CashflowBudgetSelectorOption';

function CashflowBudgetSelector() {
  const { views } = useSelector(state => state.dashboard);
  const { theme } = useSelector(state => state.menu);
  const [show, setShow] = useState(false);

  const toggle = () => {
    setShow(!show);
  };

  const budgets = views[1].options.map((budget, index) => {
    return (
      <CashflowBudgetSelectorOption
        option={budget}
        toggle={toggle}
        key={index}
      />
    );
  });

  return (
    <div className="cashflow-budget-selector-container">
      <Tile
        className={
          theme === 'dashwise' ? 'tile-content' : 'tile-content-view-fn'
        }
      >
        <Button
          className={`cashflow-budget-selector-button-${theme}`}
          onClick={toggle}
        >
          <img src={theme === 'dashwise' ? TrendIcon : TrendIconFn} />
          Selected Budget Name
          <Arrow shape={'arrowhead'} direction={show ? 'up' : 'down'} />
        </Button>
      </Tile>
      {show && (
        <div className={`cashflow-budget-selector-views-${theme}`}>
          {budgets}
        </div>
      )}
    </div>
  );
}

export default CashflowBudgetSelector;
