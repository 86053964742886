export const errors = {
  403: {
    title: '403 : ACCESS DENIED',
    text: 'We are sorry, But you don`t have the permission to view this page or resource.',
  },
  404: {
    title: '404 : PAGE NOT FOUND',
    text: 'The Page you are looking for doesn`t exist or might have been removed or is temporarily unavailable.',
  },
  500: {
    title: '500 : SERVER ERROR',
    text: 'We are currently trying to fix the problem.',
  },
};
