import { formatNumberToAmount } from '../../../utils/formatters';

import { COLORS } from '../../../utils/constants';

export const COMPOSED_CHART_PARAMETERS = {
  margin: { top: 10, right: 40, left: 10, bottom: 10 },
  barCategoryGap: 0,
  barGap: 10,
  stackOffset: 'sign',
};

export const X_AXIS_PARAMETERS = {
  dataKey: 'month',
  tick: { fill: COLORS.blueNavy },
  tickSize: 0,
  style: {
    fontFamily: 'DM Sans',
    fontSize: '12px',
    fontWeight: '400',
    opacity: 0.5,
  },
  axisLine: { stroke: COLORS.lightGray },
  tickMargin: 10,
};

export const X_AXIS_PARAMETERS_FN = {
  dataKey: 'month',
  tick: { fill: 'rgba(0, 0, 0, 0.5)' },
  tickSize: 0,
  style: { fontFamily: 'DM Sans', fontSize: '12px', fontWeight: '400' },
  axisLine: { stroke: COLORS.lightGray },
  tickMargin: 10,
};

export const Y_AXIS_PARAMETERS = {
  style: {
    fontFamily: 'DM Sans',
    fontSize: '12px',
    fontWeight: '500',
    opacity: 0.5,
  },
  stroke: COLORS.lightGray,
  tick: { fill: COLORS.blueNavy },
  tickFormatter: formatNumberToAmount,
};

export const Y_AXIS_PARAMETERS_FN = {
  style: { fontFamily: 'DM Sans', fontSize: '12px', fontWeight: '400' },
  stroke: COLORS.lightGray,
  tick: { fill: 'rgba(0, 0, 0, 0.5)' },
  tickFormatter: formatNumberToAmount,
};

export const TOOLTIP_PARAMETERS = {
  cursor: false,
  wrapperStyle: { outline: 'none' },
};

export const REFERENCE_LINE_PARAMETERS = {
  stroke: COLORS.lightGray,
  y: 0,
};

export const LINE_PARAMETERS = {
  dot: false,
  // activeDot: false,
  strokeWidth: 2,
  isAnimationActive: false,
};

export const LINE_PARAMETERS_BUDGET = {
  dot: false,
  // activeDot: false,
  strokeWidth: 3,
  isAnimationActive: false,
};
