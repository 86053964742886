import { useSelector } from 'react-redux';
import './style.css';
import CollapseButton from '../CollapseButton/component';

export const Header = ({ children }) => <header>{children}</header>;

export const Title = ({ children }) => {
  const { theme } = useSelector(state => state.menu);
  return <div className={`title-${theme}`}>{children}</div>;
};

export const Main = ({ children }) => {
  const { theme } = useSelector(state => state.menu);
  const { sideMenuCollapsed } = useSelector(state => state.menu);
  return (
    <main
      className={`main-${theme}`}
      style={{
        left: sideMenuCollapsed ? '120px' : '312px',
        width: sideMenuCollapsed ? 'calc(100% - 120px)' : 'calc(100% - 312px)',
        transition: 'all 0.7s ease-in-out',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: '-8px',
          top: '94px',
        }}
      >
        <CollapseButton />
      </div>
      {children}
    </main>
  );
};

export const Content = ({ children }) => (
  <section className="content">{children}</section>
);
