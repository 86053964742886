import './style.css';

function LoginButton({ onClick, name, image }) {
  return (
    <div
      className="login-with-account"
      onClick={() => onClick()}
      role="presentation"
    >
      <div className="company-logo">
        <img src={image} alt="" />
      </div>
      <div className="login-text">Login with {name}</div>
    </div>
  );
}

export default LoginButton;
