import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { savingClientsSet, usersSet } from '../../state/reducers/adminSlice';
import SelectIcon from '../../static/svg/select-arrow.svg';
import * as API from '../../utils/api';
import './style.css';
import Checkbox from '../Checkbox';

function UserTableSelectClients({ data, userId }) {
  const dispatch = useDispatch();
  const { clients } = useSelector(state => state.admin);

  const [show, setShow] = useState(false);
  const [clientsSelected, setClientsSelected] = useState([]);
  const [clientsTrans, setClientsTrans] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    transformClients();
  }, [clients]);

  const transformClients = () => {
    let clientsTemp = [];
    let clientIds = [];
    data.forEach(item => {
      const temp = clients.find(client => item === client.id);
      if (temp) {
        clientIds.push(temp.id);
        clientsTemp.push(temp);
      }
    });
    const clientsTransTemp = clients.filter(
      item => !clientIds.includes(item.id)
    );
    setClientsTrans([...clientsTemp, ...clientsTransTemp]);
    setClientsSelected(clientsTemp);
  };

  const showHandler = () => {
    setShow(!show);
  };

  const handleAddClient = async client => {
    const newClients = [...clientsSelected];
    const clientAlreadyAdded = clientsSelected.find(
      item => item.id === client.id
    );
    if (!clientAlreadyAdded) {
      newClients.push(client);
      setClientsSelected(newClients);
    }
    setSaving(true);
  };

  const handleRemoveClient = async id => {
    const oldClients = [...clientsSelected];
    const newClients = oldClients.filter(client => client.id !== id);
    setClientsSelected(newClients);
    setSaving(true);
  };

  const handleChecked = id => {
    const client = clientsSelected.find(item => item.id === id);

    if (client) {
      return true;
    } else {
      return false;
    }
  };

  const handleCancel = async () => {
    transformClients();
    setSaving(false);
  };

  const handleSave = async () => {
    try {
      dispatch(savingClientsSet(userId));
      setSaving(true);
      const temp = [];
      clientsSelected.forEach(item => temp.push(item.id));
      await API.editUserClients(userId, JSON.stringify(temp));
      dispatch(savingClientsSet(null));
      const response = await API.getUsersAdmin();
      dispatch(usersSet(response.data.data));
      setSaving(false);
    } catch (e) {
      dispatch(savingClientsSet(null));
      setSaving(false);
      console.log(e);
    }
  };

  const options = clientsTrans.map((client, index) => (
    <div
      key={index}
      className="client-selector-option"
      style={{ cursor: saving ? 'none' : 'pointer' }}
    >
      <Checkbox
        item={client.name}
        logo={client.url}
        onChange={e => {
          if (e.target.checked) {
            handleAddClient(client);
          } else {
            handleRemoveClient(client.id);
          }
        }}
        checked={handleChecked(client.id)}
      />
    </div>
  ));

  return (
    <>
      <div className="client-selector" onClick={showHandler}>
        <span></span>
        <span>Select Clients</span>

        <img src={SelectIcon} width={18} height={18} />
        {saving && (
          <div className="user-clients-save-container">
            <button className="client-table-cell-save" onClick={handleSave}>
              Save
            </button>
            <button className="client-table-cell-save" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        )}
      </div>
      {show && <div className="user-clients-selector-options">{options}</div>}
    </>
  );
}

export default UserTableSelectClients;
