import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  sideMenuCollapseClicked,
  menuClicked,
  theme,
} from '../../state/reducers/menuSlice';
import arrow from '../../static/svg/arrow-black.svg';
import './style.css';

function CollapseButton() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(state => state.dashboard);
  const [arrowClass, setArrowClass] = useState('collapse-arrow left');

  const handleClick = () => {
    dispatch(sideMenuCollapseClicked());
    dispatch(menuClicked());
    if (arrowClass === 'collapse-arrow right') {
      setArrowClass('collapse-arrow left');
    } else {
      setArrowClass('collapse-arrow right');
    }
  };

  return (
    <>
      {!isLoading && (
        <div className="collapse-container" onClick={handleClick}>
          <img src={arrow} className={arrowClass} alt="treeview arrow" />
        </div>
      )}
    </>
  );
}

export default CollapseButton;
