import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rowEditIndex: null,
  rowUserEditIndex: null,
  deleteModal: false,
  add: false,
  addUser: false,
  clients: [],
  divisions: [],
  clientToDelete: null,
  users: [],
  roles: [],
  userToDelete: null,
  savingClients: null,
  savingDivisions: null,
  userEditMenuIndex: null,
  clientEditMenuIndex: null,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    rowEditIndexSelected: (state, { payload }) => {
      state.rowEditIndex = payload;
    },
    deleteModalClicked: (state, { payload }) => {
      state.deleteModal = payload;
    },
    addClicked: (state, { payload }) => {
      state.add = payload;
    },
    addUserClicked: (state, { payload }) => {
      state.addUser = payload;
    },
    clientsSet: (state, { payload }) => {
      state.clients = payload;
    },
    divisionsSet: (state, { payload }) => {
      state.divisions = payload;
    },
    deleteClient: (state, { payload }) => {
      state.clientToDelete = payload;
    },
    usersSet: (state, { payload }) => {
      state.users = payload;
    },
    rolesSet: (state, { payload }) => {
      state.roles = payload;
    },
    deleteUser: (state, { payload }) => {
      state.userToDelete = payload;
    },
    rowUserEditIndexSelected: (state, { payload }) => {
      state.rowUserEditIndex = payload;
    },
    savingClientsSet: (state, { payload }) => {
      state.savingClients = payload;
    },
    savingDivisionsSet: (state, { payload }) => {
      state.savingDivisions = payload;
    },
    userEditMenuIndexSet: (state, { payload }) => {
      state.userEditMenuIndex = payload;
    },
    clientEditMenuIndexSet: (state, { payload }) => {
      state.clientEditMenuIndex = payload;
    },
  },
});

export const {
  rowEditIndexSelected,
  deleteModalClicked,
  addClicked,
  clientsSet,
  divisionsSet,
  deleteClient,
  usersSet,
  addUserClicked,
  rolesSet,
  deleteUser,
  rowUserEditIndexSelected,
  savingClientsSet,
  savingDivisionsSet,
  userEditMenuIndexSet,
  clientEditMenuIndexSet,
} = adminSlice.actions;

export default adminSlice.reducer;
