import { useSelector } from 'react-redux';

import {
  formatDateToMonth,
  formatNumberToAmount,
  formatNumberToAmountPercentage,
  formatDateToQuarter,
} from '../../utils/formatters';
import './style.css';

const CustomTooltip = ({ active, payload, label, period }) => {
  const { selectedDeepdiveMetric } = useSelector(state => state.dashboard);
  const { theme } = useSelector(state => state.menu);
  const actual = [];
  const budget = [];
  if (payload) {
    payload.forEach(item => {
      if (item.name.includes('Budget')) {
        budget.push(item);
      } else {
        actual.push(item);
      }
    });
  }

  const itemsActual = actual.map((item, index) => {
    return (
      <div className="tooltip-actual" key={index}>
        <div className={`tooltip-item-label-${theme}`}>
          {theme === 'dashwise' && (
            <div
              className="tooltip-color"
              style={{
                backgroundColor:
                  item.name === 'Net Income'
                    ? 'rgba(231, 187, 159, 1)'
                    : item.name === 'EBITDA'
                    ? 'rgba(246, 204, 72, 1)'
                    : item.name === 'Gross Profit'
                    ? 'rgba(75, 165, 220, 1)'
                    : item.name === 'Gross Profit Margin'
                    ? 'rgb(75, 165, 220)'
                    : item.name === 'EBITDA Margin'
                    ? 'rgb(246, 204, 72)'
                    : item.name === 'Net Income Margin'
                    ? 'rgb(231, 187, 159)'
                    : item.color,
              }}
            ></div>
          )}
          {theme === 'finsights' && (
            <div
              className="tooltip-color"
              style={{
                backgroundColor:
                  item.name === 'Net Income'
                    ? 'rgb(149, 196, 243)'
                    : item.name === 'EBITDA'
                    ? 'rgb(238, 128, 89)'
                    : item.name === 'Gross Profit'
                    ? 'rgba(249, 219, 123, 1)'
                    : item.name === 'Gross Profit Margin'
                    ? 'rgba(249, 219, 123, 1)'
                    : item.name === 'EBITDA Margin'
                    ? 'rgb(238, 128, 89)'
                    : item.name === 'Net Income Margin'
                    ? 'rgb(149, 196, 243)'
                    : item.color,
              }}
            ></div>
          )}
          <span>{item.name}</span>
        </div>
        <span className={`tooltip-value-${theme}`}>
          {item.name === 'Gross Profit Margin' ||
          item.name === 'EBITDA Margin' ||
          item.name === 'Net Income Margin' ||
          selectedDeepdiveMetric === 'Gross Profit Margin'
            ? formatNumberToAmountPercentage(item.value)
            : formatNumberToAmount(item.value)}
        </span>
      </div>
    );
  });

  const itemsBudget = budget.map(item => {
    return (
      <div className="tooltip-budget">
        <span className="tooltip-value">
          {selectedDeepdiveMetric === 'Gross Profit Margin' ||
          item.name === 'Gross Profit Margin Budget' ||
          item.name === 'EBITDA Margin Budget' ||
          item.name === 'Net Income Margin Budget'
            ? formatNumberToAmountPercentage(item.value)
            : formatNumberToAmount(item.value)}
        </span>
      </div>
    );
  });
  // returns custom tooltip component
  if (!active) {
    return null;
  } else {
    return (
      <div className="tooltip-wrapper">
        <div className={`tooltip-label-${theme}`}>
          {period === 'mulyears'
            ? formatDateToQuarter(label)
            : formatDateToMonth(label)}
        </div>

        <div className="tooltip-legend-wrapper">
          <div>
            <div className="tooltip-actual">
              <span></span>
              <span
                className={`tooltip-column-name-${theme}`}
                style={{
                  color: theme === 'finsights' && 'rgba(0, 0, 0, 0.5)',
                }}
              >
                ACTUAL
              </span>
            </div>
            {itemsActual}
          </div>
          {budget.length > 0 && (
            <div className="tooltip-right-column">
              <div className="tooltip-budget">
                <span
                  className={`tooltip-column-name-${theme}`}
                  style={{
                    color: theme === 'finsights' && 'rgba(0, 0, 0, 0.5)',
                  }}
                >
                  BUDGET
                </span>
              </div>
              {itemsBudget}
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default CustomTooltip;
