import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Api from '../../utils/api';

import NavMenuDropdownItem from '../NavMenuDropdownItem/component';

import './style.css';
import NavMenuDropdown from '../NavMenuDropdown/component';

import {
  dashboardSelected,
  divisionSelected,
  getBudgetScenarios,
  getData,
  isLoadingSet,
  budgetSet,
  budgetVarSet,
  periodChanged,
  dateChanged,
} from '../../state/reducers/dashboardSlice';
import {
  clientIndexSelected,
  clientSelected,
  dropdownHeaderClicked,
  divisionDataSet,
  periodsDataSet,
  logoSelected,
  menuClicked,
  adminOptionSelected,
  periodIndexSelected,
} from '../../state/reducers/menuSlice';

function NavMenu({ title, menuItems, icon, background, color, check }) {
  const dispatch = useDispatch();
  const { clients } = useSelector(state => state.menu);
  const { selectedClientIndex, sideMenuCollapsed, selectedPeriodIndex } =
    useSelector(state => state.menu);
  const { selectedPeriod, selectedDashboard } = useSelector(
    state => state.dashboard
  );
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (title === 'Clients') {
      setActive(selectedClientIndex);
    }
  }, [selectedClientIndex]);

  const handleClick = async index => {
    setActive(index);
    dispatch(menuClicked());

    if (title === 'Dashboards') {
      if (
        (selectedPeriod === 'rf' || selectedPeriod === 'mulyears') &&
        selectedDashboard === 'profit-and-loss'
      ) {
        dispatch(periodChanged('year'));
      }

      if (selectedDashboard === 'cashflow') {
        dispatch(periodChanged('year'));
      }

      dispatch(dashboardSelected(index));

      if (index !== 2) {
        dispatch(getData());
      }
    }

    if (title === 'Clients') {
      if (selectedDashboard === 'admin') {
        dispatch(dashboardSelected(0));
      }

      if (selectedDashboard === 'cashflow') {
        dispatch(dashboardSelected(0));
        dispatch(periodChanged('year'));
      }

      dispatch(isLoadingSet(true));
      let month = new Date().getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      dispatch(dateChanged(`${new Date().getFullYear()}-${month}-01`));
      dispatch(budgetSet(''));
      dispatch(budgetVarSet(''));
      dispatch(dropdownHeaderClicked(false));
      dispatch(clientIndexSelected(index));
      dispatch(clientSelected(menuItems[index].id));
      dispatch(logoSelected(menuItems[index].logo_url));
      const divisions = clients[index].divisions;

      dispatch(divisionDataSet(divisions));
      dispatch(divisionSelected(divisions[0].code));
      const periods = await Api.getSelectorOptions(divisions[0].code);
      dispatch(periodsDataSet(periods.data.data));
      dispatch(getBudgetScenarios());
      dispatch(getData());
    }

    if (title === 'Admin') {
      dispatch(adminOptionSelected(index));
      dispatch(dashboardSelected(3));
    }
  };

  const items = menuItems.map((item, index) => (
    <NavMenuDropdownItem
      key={index}
      item={title === 'Dashboards' || title === 'Admin' ? item : item.name}
      active={active === index}
      click={() => handleClick(index)}
      color={color}
      check={check}
      collapsed={sideMenuCollapsed}
    />
  ));

  return (
    <>
      <NavMenuDropdown
        title={title}
        icon={icon}
        background={background}
        color={color}
        check={check}
      >
        {items}
      </NavMenuDropdown>
    </>
  );
}

export default NavMenu;
