import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  deleteModalClicked,
  addClicked,
  clientsSet,
  deleteClient,
} from '../../state/reducers/adminSlice';
import * as API from '../../utils/api';
import ClientsTableRow from '../ClientsTableRow';
import AddIcon from '../../static/svg/add.svg';
import SyncIcon from '../../static/svg/sync.svg';
import './style.css';

function ClientsTable({ data }) {
  const dispatch = useDispatch();
  const { deleteModal, add, clientToDelete } = useSelector(
    state => state.admin
  );
  const [name, setName] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [username, setUsername] = useState('');
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  let clients = [];
  if (data) {
    clients = data.map((item, index) => {
      return <ClientsTableRow key={item.id} data={item} first={index === 0} />;
    });
  }

  const handleCancel = async () => {
    dispatch(deleteClient(null));
    dispatch(deleteModalClicked(false));
  };

  const handleDelete = async () => {
    setDeleting(true);
    await API.deleteClient(clientToDelete);
    const response = await API.getClientsAdmin();
    dispatch(clientsSet(response.data.data));
    dispatch(deleteClient(null));
    dispatch(deleteModalClicked(false));
    setDeleting(false);
  };

  const handleAdd = () => {
    dispatch(addClicked(true));
  };

  const handleSave = async () => {
    setSaving(true);
    await API.addClient(name, logoUrl, username);
    const response = await API.getClientsAdmin();
    dispatch(clientsSet(response.data.data));
    dispatch(addClicked(false));
    setSaving(false);
    setName('');
    setLogoUrl('');
    setUsername('');
  };

  const handleCancelAdd = () => {
    setName('');
    setLogoUrl('');
    setUsername('');
    dispatch(addClicked(false));
  };

  return (
    <div className="clients-table-wrapper">
      {deleteModal && (
        <div className="clients-table-delete-modal">
          {!deleting && <h5>Are you sure?</h5>}
          {deleting && <h5>Deleting the client...</h5>}
          <div className="clients-table-delete-modal-btns">
            {!deleting && (
              <button
                className="clients-table-delete-modal-btn"
                onClick={handleCancel}
              >
                Cancel
              </button>
            )}
            {!deleting && (
              <button
                className="clients-table-delete-modal-btn"
                onClick={handleDelete}
              >
                Delete
              </button>
            )}
            {deleting && (
              <button
                className="clients-table-delete-modal-btn"
                onClick={handleDelete}
              >
                <img src={SyncIcon} className="client-table-row-loading" />
              </button>
            )}
          </div>
        </div>
      )}
      <div className="clients-table-container">
        <div
          className="clients-table-row"
          style={{
            borderTop: 'none',
            position: 'sticky',
            top: '0',
            backgroundColor: '#fff',
            borderBottom: '1px solid var(--graycloudy)',
            zIndex: '101',
          }}
        >
          <div className="clients-table-cell-0 clients-table-row-column">
            Name
          </div>
          <div className="clients-table-cell-1 clients-table-row-column">
            ID
          </div>
          <div className="clients-table-cell-2 clients-table-row-column">
            Logo
          </div>
          <div className="clients-table-cell-3 clients-table-row-column">
            Exact User
          </div>
        </div>
        {clients}
        <div className="clients-table-row-add">
          {add && (
            <div className="clients-table-row-new">
              <div className="clients-table-cell-0 ">
                <input
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder="Name client"
                  className="client-table-input-new"
                />
              </div>
              <div className="clients-table-cell-1"></div>
              <div className="clients-table-cell-2">
                <input
                  value={logoUrl}
                  onChange={e => setLogoUrl(e.target.value)}
                  placeholder="Logo url"
                  className="client-table-input-new"
                />
              </div>
              <div className="clients-table-cell-3">
                <input
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  placeholder="Exact username"
                  className="client-table-input-new"
                />
              </div>
            </div>
          )}
          <div className="clients-table-add">
            {!add && (
              <div onClick={handleAdd}>
                <img src={AddIcon} width={30} />
                <span>Add New</span>
              </div>
            )}
            {add && !saving && (
              <button onClick={handleSave} className="clients-table-add-btn">
                Save
              </button>
            )}
            {add && saving && (
              <button onClick={handleSave} className="clients-table-add-btn">
                <img src={SyncIcon} className="client-table-row-loading" />
              </button>
            )}
            {add && !saving && (
              <button
                onClick={handleCancelAdd}
                className="clients-table-add-btn"
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientsTable;
