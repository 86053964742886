function TableIcon({ fill }) {
  return (
    <>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M3.1875 10.1562V6.5H9.65625V10.1562H3.1875ZM3.1875 11.8438H9.65625V16.0625H3.75C3.43359 16.0625 3.1875 15.8164 3.1875 15.5V11.8438ZM11.3438 16.0625V11.8438H17.8125V15.5C17.8125 15.8164 17.5312 16.0625 17.25 16.0625H11.3438ZM17.8125 10.1562H11.3438V6.5H17.8125V10.1562ZM3.75 2C2.48438 2 1.5 3.01953 1.5 4.25V15.5C1.5 16.7656 2.48438 17.75 3.75 17.75H17.25C18.4805 17.75 19.5 16.7656 19.5 15.5V4.25C19.5 3.01953 18.4805 2 17.25 2H3.75Z"
            fill={fill}
          />
        </g>
      </svg>
    </>
  );
}

export default TableIcon;
