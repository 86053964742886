import { useSelector } from 'react-redux';
import './style.css';

const ListItemColor = ({ color }) => {
  return <div className="list-item-color" style={{ backgroundColor: color }} />;
};

const ListItemLabel = ({ label, theme }) => {
  return (
    <div
      className="list-item-label"
      style={{ color: theme && label === 'None' && '#7E7E7E' }}
    >
      {label}
    </div>
  );
};

const ListItemAmount = ({ value, theme, label }) => {
  return (
    <div
      className="list-item-number"
      style={{ color: theme && label === 'None' && '#7E7E7E' }}
    >
      {value}
    </div>
  );
};

const ListItem = ({ color, label, value, className }) => {
  const { theme } = useSelector(state => state.menu);
  return (
    <div className={`list-item-wrapper ${className}`}>
      <ListItemColor
        color={label === 'None' && theme === 'finsights' ? '#EFEFEF' : color}
      />
      <ListItemLabel label={label} theme={theme === 'finsights'} />
      <ListItemAmount
        value={value}
        theme={theme === 'finsights'}
        label={label}
      />
    </div>
  );
};

export default ListItem;
