import './style.css';

function SmallArrow({ fill, className }) {
  return (
    <>
      <svg
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M4.965 5.16569C4.65263 5.47806 4.14535 5.47806 3.83298 5.16569L0.63431 1.96703C0.404405 1.73712 0.336934 1.39477 0.461882 1.09489C0.58683 0.795016 0.876709 0.600097 1.20157 0.600097L7.59891 0.602597C7.92127 0.602597 8.21365 0.797516 8.3386 1.09739C8.46355 1.39727 8.39357 1.73962 8.16617 1.96953L4.9675 5.16819L4.965 5.16569Z"
          fill={fill}
        />
      </svg>
    </>
  );
}

export default SmallArrow;
