import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  deleteModalClicked,
  addUserClicked,
  usersSet,
  deleteUser,
} from '../../state/reducers/adminSlice';
import * as API from '../../utils/api';
import UsersTableRow from '../UsersTableRow';
import AddIcon from '../../static/svg/add.svg';
import SyncIcon from '../../static/svg/sync.svg';
import './style.css';

function UsersTable({ data }) {
  const dispatch = useDispatch();
  const { deleteModal, addUser, roles, userToDelete, users } = useSelector(
    state => state.admin
  );

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [userrole, setUserRole] = useState(1);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {}, [users]);

  let usersList = [];
  if (data) {
    usersList = data.map((item, index) => {
      return <UsersTableRow key={index} data={item} first={index === 0} />;
    });
  }

  let userRolesOptions = [];

  if (data) {
    userRolesOptions = roles.map((item, index) => {
      return (
        <option key={index} value={item.id}>
          {item.description}
        </option>
      );
    });
  }

  const handleCancel = async () => {
    dispatch(deleteModalClicked(false));
  };

  const handleDelete = async () => {
    setDeleting(true);
    await API.deleteUser(userToDelete);
    const response = await API.getUsersAdmin();
    dispatch(usersSet(response.data.data));
    dispatch(deleteUser(null));
    dispatch(deleteModalClicked(false));
    setDeleting(false);
  };

  const handleAdd = () => {
    dispatch(addUserClicked(true));
  };

  const handleSave = async () => {
    setSaving(true);
    await API.addUser(name, email, userrole);
    const response = await API.getUsersAdmin();
    dispatch(usersSet(response.data.data));
    dispatch(addUserClicked(false));
    setSaving(false);
    setName('');
    setEmail('');
    setUserRole(1);
  };

  const handleCancelAdd = () => {
    setName('');
    setEmail('');
    dispatch(addUserClicked(false));
  };

  const handleUserRole = event => {
    setUserRole(event.target.value);
  };

  return (
    <div className="users-table-wrapper">
      {deleteModal && (
        <div className="clients-table-delete-modal">
          {!deleting && <h5>Are you sure?</h5>}
          {deleting && <h5>Deleting the user...</h5>}
          <div className="clients-table-delete-modal-btns">
            {!deleting && (
              <button
                className="clients-table-delete-modal-btn"
                onClick={handleCancel}
              >
                Cancel
              </button>
            )}
            {!deleting && (
              <button
                className="clients-table-delete-modal-btn"
                onClick={handleDelete}
              >
                Delete
              </button>
            )}
            {deleting && (
              <button
                className="clients-table-delete-modal-btn"
                onClick={handleDelete}
              >
                <img src={SyncIcon} className="client-table-row-loading" />
              </button>
            )}
          </div>
        </div>
      )}
      <div className="users-table-container ">
        <div
          className="clients-table-row"
          style={{
            borderTop: 'none',
            position: 'sticky',
            top: '0',
            backgroundColor: '#fff',
            zIndex: '101',
          }}
        >
          <div
            className="users-table-cell-0 users-table-row-column"
            style={{ borderTop: 'none' }}
          >
            Name
          </div>
          <div
            className="users-table-cell-1 users-table-row-column"
            style={{ borderTop: 'none' }}
          >
            ID
          </div>
          <div
            className="users-table-cell-2 users-table-row-column"
            style={{ borderTop: 'none' }}
          >
            Email
          </div>
          <div
            className="users-table-cell-3 users-table-row-column"
            style={{ borderTop: 'none' }}
          >
            Role
          </div>
          <div
            className="users-table-cell-4 users-table-row-column"
            style={{ borderTop: 'none' }}
          >
            Client Access
          </div>
          <div
            className="users-table-cell-5 users-table-row-column"
            style={{ borderTop: 'none' }}
          >
            Division Access
          </div>
        </div>
        {usersList}
        <div className="users-table-row-add">
          {addUser && (
            <div className="users-table-row-new">
              <div className="users-table-cell-0 ">
                <input
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder="Name user"
                  className="user-table-input-new"
                />
              </div>
              <div className="users-table-cell-1"></div>
              <div className="users-table-cell-2">
                <input
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email user"
                  className="user-table-input-new"
                />
              </div>
              <div className="users-table-cell-3">
                <select value={userrole} onChange={handleUserRole}>
                  {userRolesOptions}
                </select>
              </div>
            </div>
          )}
          <div className="clients-table-add">
            {!addUser && (
              <div onClick={handleAdd}>
                <img src={AddIcon} width={30} />
                <span>Add New</span>
              </div>
            )}
            {addUser && !saving && (
              <button onClick={handleSave} className="clients-table-add-btn">
                Save
              </button>
            )}
            {addUser && saving && (
              <button onClick={handleSave} className="clients-table-add-btn">
                <img src={SyncIcon} className="client-table-row-loading" />
              </button>
            )}
            {addUser && !saving && (
              <button
                onClick={handleCancelAdd}
                className="clients-table-add-btn"
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsersTable;
