import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { savingDivisionsSet, usersSet } from '../../state/reducers/adminSlice';
import SelectIcon from '../../static/svg/select-arrow.svg';
import * as API from '../../utils/api';
import './style.css';
import Checkbox from '../Checkbox';

function UserTableSelectDivisions({ data, userId, type, client }) {
  const dispatch = useDispatch();
  const { clients, divisions, users } = useSelector(state => state.admin);

  const [show, setShow] = useState(false);
  const [divisionsSelected, setdivisionsSelected] = useState([]);
  const [divisionsTrans, setdivisionsTrans] = useState([]);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    transformDivisions();
  }, [clients, users]);

  const transformDivisions = () => {
    const divTemp = divisions.filter(item => item.ew_client_id === client);
    setdivisionsTrans(divTemp);
    setdivisionsSelected(data);
  };

  const showHandler = () => {
    setShow(!show);
  };

  const handleAddClient = division => {
    const newDivisions = [...divisionsSelected];
    if (!divisionsSelected.includes(division.code)) {
      newDivisions.push(division.code);
      setdivisionsSelected(newDivisions);
    }
    setSaving(true);
  };

  const handleRemoveClient = code => {
    const oldDivisions = [...divisionsSelected];
    const newDivisions = oldDivisions.filter(division => division !== code);
    setdivisionsSelected(newDivisions);
    setSaving(true);
  };

  const handleChecked = code => {
    if (divisionsSelected.includes(code)) {
      return true;
    } else {
      return false;
    }
  };

  const handleCancel = async () => {
    transformDivisions();
    setSaving(false);
  };

  const handleSave = async () => {
    try {
      dispatch(savingDivisionsSet(userId));
      setSaving(true);
      await API.editUserDivisions(userId, JSON.stringify(divisionsSelected));
      dispatch(savingDivisionsSet(null));
      const response = await API.getUsersAdmin();
      dispatch(usersSet(response.data.data));
      setSaving(false);
    } catch (e) {
      dispatch(savingDivisionsSet(null));
      setSaving(false);
      console.log(e);
    }
  };

  const options = divisionsTrans.map((division, index) => (
    <div
      key={index}
      className="client-selector-option"
      style={{ cursor: saving ? 'none' : 'pointer' }}
    >
      <Checkbox
        item={division.description}
        onChange={e => {
          if (e.target.checked) {
            handleAddClient(division);
          } else {
            handleRemoveClient(division.code);
          }
        }}
        checked={handleChecked(division.code)}
      />
    </div>
  ));

  return (
    <>
      <div className="client-selector" onClick={showHandler}>
        <span></span>
        {type === 'client' && <span>Select Clients</span>}
        {type === 'division' && <span>Select Divisions</span>}

        <img src={SelectIcon} width={18} height={18} />
        {saving && (
          <div className="user-clients-save-container">
            <button className="client-table-cell-save" onClick={handleSave}>
              Save
            </button>
            <button className="client-table-cell-save" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        )}
      </div>
      {show && <div className="user-division-selector-options">{options}</div>}
    </>
  );
}

export default UserTableSelectDivisions;
