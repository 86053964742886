import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getNodes } from '../utils/utils';
import Node from '../Node';
import './style.css';

function Branch({
  data,
  open,
  indentation,
  last,
  branch,
  lastNode,
  branchesPaths,
  clicked,
  onAnalyseClick,
  rootBranchIndex,
  analysableNodes,
  columnOrder,
  styling,
}) {
  const { selectedDashboard } = useSelector(state => state.dashboard);
  const [active, setActive] = useState(false);
  const [opened, setOpened] = useState([]);

  useEffect(() => {
    if (indentation === 1 && selectedDashboard === 'balance-sheet') {
      setOpened([0]);
    }
  }, []);

  const handleToggle = index => {
    setActive(!active);
    const newOpened = opened;
    if (!opened.includes(index)) {
      newOpened.push(index);
      setOpened(newOpened);
    } else {
      const itemIndex = opened.indexOf(index);
      newOpened.splice(itemIndex, 1);
      setOpened(newOpened);
    }
  };

  const items = data.map((item, index) => {
    return (
      <div key={index}>
        <Node
          item={item.name}
          values={item.values}
          arrow={item.depth > 0}
          indentation={indentation}
          click={() => handleToggle(index)}
          last={last && index === data.length - 1}
          branch={branch}
          lastNode={lastNode}
          clicked={clicked}
          onAnalyseClick={onAnalyseClick}
          rootBranchIndex={rootBranchIndex}
          analysableNodes={analysableNodes}
          columnOrder={columnOrder}
          styling={styling}
          open={indentation === 1 && selectedDashboard === 'balance-sheet'}
        />
        {item.depth > 0 ? (
          <Branch
            data={getNodes(item.data)}
            indentation={indentation + 2}
            open={opened.includes(index) && open}
            last={last && index === data.length - 1}
            branch={branch}
            height={branchesPaths[branch].length * 100}
            lastNode={lastNode}
            branchesPaths={branchesPaths}
            clicked={clicked}
            onAnalyseClick={onAnalyseClick}
            rootBranchIndex={rootBranchIndex}
            analysableNodes={analysableNodes}
            columnOrder={columnOrder}
            styling={styling}
          />
        ) : null}
      </div>
    );
  });
  return (
    <div
      className="branch"
      style={
        open
          ? {
              maxHeight: `${branchesPaths[branch] * 50}px`,
              overflow: 'unset',
              transition: 'max-height 0.7s ease-in-out',
            }
          : {
              maxHeight: '0px',
              overflow: 'hidden',
              transition: 'max-height 0.4s ease-in-out',
            }
      }
    >
      {items}
    </div>
  );
}

export default Branch;
