import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import Arrow from '../../Arrow';
import Tile from '../../Tile';

import Button from '../component';

import * as Api from '../../../utils/api';

import './style.css';

const ButtonDataUpdate = ({ selectedClient, onClick }) => {
  const { theme } = useSelector(state => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await Api.updateClientData(selectedClient);
      setIsSuccess(true);
    } catch (error) {
      setIsSuccess(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      setTimeout(() => {
        onClick();
      }, 1000);
    }
  }, [isSuccess]);

  const buttonClassName = `refresh-button ${isLoading ? 'loading' : ''} ${
    isSuccess ? 'success' : ''
  }`;

  return (
    <div className={'refresh-button-container'}>
      <Tile className={theme === 'finsights' && 'tile-content-fn'}>
        <Button className={buttonClassName} onClick={handleClick}>
          <Arrow shape={theme === 'dashwise' ? 'circular' : 'circular-black'} />
          <div className={`refresh-button-text-${theme}`}>
            {isLoading ? 'Loading...' : isSuccess ? 'Success!' : 'Update Data'}
          </div>
        </Button>
      </Tile>
    </div>
  );
};

export default ButtonDataUpdate;
