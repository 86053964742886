import { useSelector } from 'react-redux';
import Arrow from '../../Arrow';
import Tile from '../../Tile';
import Button from '../component';

import './style.css';

function ButtonBack({ onClick, show }) {
  const { theme } = useSelector(state => state.menu);
  return (
    <div
      className="button-back-container"
      style={{
        opacity: show ? '1' : '0',
        visibility: show ? 'visible' : 'hidden',
      }}
    >
      <Tile
        className={
          theme === 'dashwise' ? 'tile-button-back' : 'tile-button-back-fn'
        }
      >
        <Button className={`button-back-${theme}`} onClick={onClick}>
          <Arrow shape="arrowhead" />
          Go Back
        </Button>
      </Tile>
    </div>
  );
}

export default ButtonBack;
