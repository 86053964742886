export const DROPDOWNS = [
  {
    label: 'Group by',
    defaultIndex: 4,
    options: [
      'accounts (top 10)',
      'accounts (all)',
      'ledger',
      'ledgerGrouping',
      'costCenter',
      '',
    ],
  },
  {
    label: 'Order by',
    defaultIndex: 2,
    options: ['amountAscending', 'amountDescending', ''],
  },
  {
    label: 'Compare to',
    defaultIndex: 2,
    options: ['previousPeriod', 'budget', ''],
  },
];

export const DROPDOWNS_GROSS_PROFIT_MARGIN = [
  {
    label: 'Group by',
    defaultIndex: 4,
    options: ['costCenter', ''],
  },
  {
    label: 'Order by',
    defaultIndex: 2,
    options: ['amountAscending', 'amountDescending', ''],
  },
  {
    label: 'Compare to',
    defaultIndex: 2,
    options: ['previousPeriod', 'budget', ''],
  },
];

export const HEADER_TILE_HEIGHT = 1.2 / 7;
export const TABLE_TILE_HEIGHT = 1 - HEADER_TILE_HEIGHT;
