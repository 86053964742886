import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { budgetSet } from '../../../../state/reducers/dashboardSlice';
import Check from '../../../../static/svg/check-green.svg';
import CheckFn from '../../../../static/svg/check-orange.svg';

import './style.css';

function CashflowBudgetSelectorOption({ option, toggle }) {
  const dispatch = useDispatch();
  const { budget } = useSelector(state => state.dashboard);
  const { theme } = useSelector(state => state.menu);
  const [background, setBackground] = useState(false);
  const handleMouseEnter = () => {
    setBackground(true);
  };

  const handleMouseLeave = () => {
    setBackground(false);
  };

  const handleClick = () => {
    toggle();
    dispatch(budgetSet(option));
  };
  return (
    <div
      className={
        background
          ? `cashflow-budget-selector-option-${theme} cashflow--budget-option-active-${theme}`
          : `cashflow-budget-selector-option-${theme}`
      }
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span
        className={`cashflow-budget-selector-option-span cashflow-budget-option-span-active`}
      >
        {option}
      </span>
      {budget === option && (
        <img
          src={theme === 'dashwise' ? Check : CheckFn}
          width={20}
          height={20}
        />
      )}
    </div>
  );
}

export default CashflowBudgetSelectorOption;
