import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clientsSet,
  divisionsSet,
  usersSet,
  rolesSet,
} from '../../state/reducers/adminSlice';
import { isLoadingSet } from '../../state/reducers/dashboardSlice';
import * as API from '../../utils/api';
import ClientsTable from '../ClientsTable';
import UsersTable from '../UsersTable/component';
import Section from '../Layout/Section';
import Tile from '../Tile';
import './style.css';

function AdminDashboard() {
  const dispatch = useDispatch();
  const { clients, users } = useSelector(state => state.admin);
  const { selectedAdminOption } = useSelector(state => state.menu);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    dispatch(isLoadingSet(true));
    const responseClients = await API.getClientsAdmin();
    const responseDivisions = await API.getDivisionsAdmin();
    const responseUsers = await API.getUsersAdmin();
    const responseRoles = await API.getUserRoles();
    dispatch(clientsSet(responseClients.data.data));
    dispatch(divisionsSet(responseDivisions.data.data));
    dispatch(usersSet(responseUsers.data.data));
    dispatch(rolesSet(responseRoles.data.data));
    dispatch(isLoadingSet(false));
  };

  return (
    <Section.Primary>
      <Tile className="tile-content-admin">
        {selectedAdminOption === 'Clients' && <ClientsTable data={clients} />}
        {selectedAdminOption === 'Users' && <UsersTable data={users} />}
      </Tile>
    </Section.Primary>
  );
}

export default AdminDashboard;
