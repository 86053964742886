import { useSelector } from 'react-redux';
import TreeViewArrow from '../TreeViewArrow';
import AnalyzeButton from '../AnalyzeButton';
import './style.css';

function Cell({
  item,
  lastNode,
  indentation,
  arrow,
  analyzebtn,
  click,
  last,
  clicked,
  branch,
  showBtn,
  onAnalyseClick,
  rootBranchIndex,
  styling,
  setStyle,
  open,
  first,
  background,
}) {
  const { theme } = useSelector(state => state.menu);
  let fontWeight;
  let fontStyle;
  if (styling) {
    fontWeight = indentation === 1 && setStyle ? setStyle.weight : '300';
    fontStyle = indentation === 1 && setStyle ? setStyle.style : 'normal';
  }

  if (!styling) {
    fontWeight = indentation === 1 ? '700' : '500';
    fontStyle = 'normal';
  }

  if (item === 'Unprocessed Result') {
    fontWeight = '400';
    fontStyle = 'italic';
  }
  return (
    <div
      className={first ? `cell-${theme} first-column` : `cell-${theme}`}
      style={{
        background: background,
        transition: 'background 0.2s linear',
        fontWeight: fontWeight,
        fontStyle: fontStyle,
        paddingLeft: `${indentation}rem`,
        paddingTop: first && !analyzebtn ? '0.95rem' : '0.95rem',
        borderBottom:
          item === lastNode[lastNode.length - 1] ? 'none' : '1px solid #EBEDF2',
      }}
    >
      {item}
      {arrow && (
        <TreeViewArrow
          toggle={click}
          node={item}
          last={last}
          branch={branch}
          clicked={clicked}
          open={open}
        />
      )}
      {analyzebtn && (
        <AnalyzeButton
          node={item}
          show={showBtn}
          onAnalyseClick={onAnalyseClick}
          rootBranchIndex={rootBranchIndex}
        />
      )}
    </div>
  );
}

export default Cell;
