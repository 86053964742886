import { useState, useEffect } from 'react';
import arrow from '../../../static/svg/treeview-arrow.svg';
import './style.css';

function TreeViewArrow({ toggle, node, last, clicked, open }) {
  const [arrowClass, setArrowClass] = useState('treeview-arrow');

  useEffect(() => {
    if (open) {
      setArrowClass('treeview-arrow down');
    }
  }, []);

  const handleClick = () => {
    toggle();
    if (last) {
      clicked(node);
    }
    if (arrowClass === 'treeview-arrow') {
      setArrowClass('treeview-arrow down');
    } else {
      setArrowClass('treeview-arrow');
    }
  };

  return (
    <img
      src={arrow}
      className={arrowClass}
      onClick={handleClick}
      alt="treeview arrow"
    />
  );
}

export default TreeViewArrow;
