import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  isLoadingSet,
  periodChanged,
} from '../../state/reducers/dashboardSlice';
import {
  periodIndexSelected,
  periodSelected,
  optionsUpdated,
  menuClicked,
} from '../../state/reducers/menuSlice';
import Section from '../Layout/Section';
import Tile from '../Tile';
import * as API from '../../utils/api';
import './style.css';
import CashflowChart from './CashflowChart';
import CashflowScorecard from './CashflowScorecard/component';

function CashflowDashboard() {
  const dispatch = useDispatch();
  const { selectedDivision, selectedDate, selectedPeriod, budget } =
    useSelector(state => state.dashboard);
  const { periodsMenu, options, selectedPeriodIndex, theme } = useSelector(
    state => state.menu
  );
  const [cashflowData, setCashflowData] = useState([]);

  useEffect(() => {
    getData();
  }, [selectedDate, budget]);

  const getData = async () => {
    try {
      if (selectedPeriod !== 'ytd') {
        dispatch(periodChanged('ytd'));
        const { options, defaultIndex } = periodsMenu['ytd'];
        dispatch(periodIndexSelected(defaultIndex));
        dispatch(periodSelected(selectedPeriod));
        dispatch(optionsUpdated(options));
      }

      dispatch(isLoadingSet(true));
      const response = await API.getCashFlow(
        selectedDivision,
        selectedDate,
        selectedPeriod,
        budget
      );
      setCashflowData(response.data.data);

      dispatch(isLoadingSet(false));
    } catch (e) {
      dispatch(isLoadingSet(false));
      console.log(e);
    }
  };

  return (
    <>
      <Section.Primary>
        {cashflowData[1] && <CashflowChart data={cashflowData} />}
      </Section.Primary>
      <Section.Secondary>
        {cashflowData[2] && (
          <CashflowScorecard
            number={cashflowData[2]['opening']}
            title={'Opening'}
            varia={false}
          />
        )}
        {cashflowData[2] && (
          <CashflowScorecard
            number={cashflowData[2]['mutation']}
            title={'Mutation'}
            varia={true}
          />
        )}
        {cashflowData[2] && (
          <CashflowScorecard
            number={cashflowData[2]['closing']}
            title={'Closing'}
            varia={true}
          />
        )}
      </Section.Secondary>
    </>
  );
}

export default CashflowDashboard;
