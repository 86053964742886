import { useSelector, useDispatch } from 'react-redux';
import Arrow from '../Arrow';
import Button from '../Button';
import Tile from '../Tile';
import ViewSelectorView from './ViewSelectorView';
import { viewSelectorViewSelected } from '../../state/reducers/dashboardSlice';
import { menuClicked } from '../../state/reducers/menuSlice';
import ViewIcon from '../../static/svg/graph.svg';
import ViewIconFn from '../../static/svg/graph-fn.svg';
import './style.css';

function ViewSelector({ show }) {
  const dispatch = useDispatch();
  const { views, selectedView } = useSelector(state => state.dashboard);
  const { showViews, theme } = useSelector(state => state.menu);
  const toggle = () => {
    dispatch(menuClicked('showViews'));
    if (!showViews) {
      dispatch(viewSelectorViewSelected(''));
    }
  };

  let test = views;
  if (selectedView === 'graphs') {
  }

  const viewsList = views.map((view, index) => {
    if (selectedView === 'graphs' && index < 4) {
      return (
        <ViewSelectorView
          key={index}
          view={view.view}
          options={view.options}
          show={showViews}
          click={toggle}
        />
      );
    }

    if (selectedView === 'table' && index !== 3) {
      return (
        <ViewSelectorView
          key={index}
          view={view.view}
          options={view.options}
          show={showViews}
          click={toggle}
        />
      );
    }
  });

  return (
    <div
      className="view-selector-container"
      style={{ display: show ? 'block' : 'none' }}
    >
      <Tile
        className={
          theme === 'dashwise' ? 'tile-content' : 'tile-content-view-fn'
        }
      >
        <Button className={`view-selector-button-${theme}`} onClick={toggle}>
          <img src={theme === 'dashwise' ? ViewIcon : ViewIconFn} />
          Edit View
          <Arrow
            shape={theme === 'dashwise' ? 'arrowhead' : 'arrowhead-black'}
            direction={showViews ? 'up' : 'down'}
          />
        </Button>
      </Tile>
      {showViews && (
        <div className={`view-selector-views-${theme}`}>{viewsList}</div>
      )}
    </div>
  );
}

export default ViewSelector;
