import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as LoadingAnim } from '../../static/svg/login-loading.svg';
import { ReactComponent as LoadingAnimFn } from '../../static/svg/login-loading-fn.svg';
import './style.css';

function Loader() {
  const { theme } = useSelector(state => state.menu);
  return (
    <div className="loader-background">
      {theme === 'dashwise' && <LoadingAnim />}
      {theme === 'finsights' && <LoadingAnimFn />}
    </div>
  );
}

export default Loader;
