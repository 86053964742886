import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  rowUserEditIndexSelected,
  deleteModalClicked,
  deleteClient,
  deleteUser,
  usersSet,
  userEditMenuIndexSet,
} from '../../state/reducers/adminSlice';
import * as API from '../../utils/api';
import Dots from '../../static/svg/dots.svg';
import SyncIcon from '../../static/svg/sync.svg';

import './style.css';
import UserTableClientSelector from '../UserTableClientSelector/component';
import UserTableSelectClients from '../UserTableSelectClients';
import UserTableSelectDivisions from '../UserTableSelectDivisions';

function UsersTableRow({ data, first }) {
  const dispatch = useDispatch();
  const {
    rowUserEditIndex,
    roles,
    savingClients,
    savingDivisions,
    userEditMenuIndex,
  } = useSelector(state => state.admin);
  const { userid } = useSelector(state => state.menu);
  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState(data.first_name);
  const [email, setEmail] = useState(data.email);
  const [userrole, setUserRole] = useState(data.role_id);

  const handleMouseEnter = () => {
    setShowMenu(true);
  };

  const handleMouseLeave = () => {
    setShowMenu(false);
  };

  const handleClick = () => {
    setShowSubMenu(true);
    dispatch(userEditMenuIndexSet(data.id));
  };

  const handleEdit = () => {
    setEdit(true);
    setShowSubMenu(false);
    dispatch(rowUserEditIndexSelected(data.id));
  };

  const handleDelete = async () => {
    dispatch(deleteModalClicked(true));
    dispatch(rowUserEditIndexSelected(null));
    dispatch(deleteClient(null));
    dispatch(deleteUser(data.id));
    setShowSubMenu(false);
  };

  const handleSave = async () => {
    setEdit(false);
    dispatch(rowUserEditIndexSelected(null));
    setSaving(true);
    await API.editUser(data.id, name, email, userrole);
    const response = await API.getUsersAdmin();
    dispatch(usersSet(response.data.data));
    setSaving(false);
  };

  const handleCancel = () => {
    setEdit(false);
  };

  const userRolesOptions = roles.map((item, index) => {
    return (
      <option key={index} value={item.id}>
        {item.description}
      </option>
    );
  });

  const handleUserRole = event => {
    setUserRole(event.target.value);
  };

  return (
    <div className="users-table-row" style={{ borderTop: first && 'none' }}>
      <div
        className="users-table-cell-0"
        onMouseEnter={data.id !== userid ? handleMouseEnter : undefined}
        onMouseLeave={data.id !== userid ? handleMouseLeave : undefined}
      >
        {showSubMenu && userEditMenuIndex === data.id && (
          <div className="client-table-row-menu">
            <div className="client-table-row-menu-option" onClick={handleEdit}>
              Edit User
            </div>
            <div
              className="client-table-row-menu-option"
              onClick={handleDelete}
            >
              Delete User
            </div>
          </div>
        )}
        {showMenu && (
          <img
            src={Dots}
            className="users-table-cell-menu"
            onClick={handleClick}
          />
        )}
        <input
          value={name}
          onChange={e => setName(e.target.value)}
          readOnly={!edit && rowUserEditIndex !== data.id}
          className="user-table-cell-input"
        />
      </div>
      <div className="users-table-cell-1">{data.id}</div>
      <div className="users-table-cell-2">
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          readOnly={!edit && rowUserEditIndex !== data.id}
          className="user-table-cell-input input-email"
        />
      </div>
      <div className="users-table-cell-3">
        {edit && rowUserEditIndex === data.id ? (
          <select value={userrole} onChange={handleUserRole}>
            {userRolesOptions}
          </select>
        ) : (
          data.description
        )}
      </div>
      <div className="users-table-cell-4">
        {parseInt(userrole) === 0 && 'All'}
        {parseInt(userrole) === 1 && (
          <UserTableSelectClients data={data.clients} userId={data.id} />
        )}
        {parseInt(userrole) === 2 && (
          <UserTableClientSelector client={data.clients[0]} id={data.id} />
        )}

        {edit && rowUserEditIndex === data.id && (
          <div className="user-table-save-container">
            {edit && (
              <button className="client-table-cell-save" onClick={handleSave}>
                Save
              </button>
            )}

            {edit && (
              <button className="client-table-cell-save" onClick={handleCancel}>
                Cancel
              </button>
            )}
          </div>
        )}
        {saving && (
          <div className="user-table-save-container-saving">
            {saving && (
              <button className="client-table-cell-save">
                <img src={SyncIcon} className="client-table-row-loading" />
              </button>
            )}
          </div>
        )}
        {savingClients === data.id && (
          <div className="client-table-cell-saving">
            <img src={SyncIcon} width={20} className="user-table-row-loading" />
          </div>
        )}
      </div>
      <div className="users-table-cell-5">
        {parseInt(userrole) === 0 && 'All'}
        {parseInt(userrole) === 1 && 'All'}
        {parseInt(userrole) === 2 && (
          <UserTableSelectDivisions
            data={data.divisions}
            userId={data.id}
            type="division"
            client={data.clients[0]}
          />
        )}
        {savingDivisions === data.id && (
          <div className="client-table-cell-saving">
            <img src={SyncIcon} width={20} className="user-table-row-loading" />
          </div>
        )}
      </div>
    </div>
  );
}

export default UsersTableRow;
