import { useSelector, useDispatch } from 'react-redux';
import AnalyzeIcon from '../../../static/svg/analyze-icon.svg';
import {
  periodChanged,
  metricSelected,
} from '../../../state/reducers/dashboardSlice';
import './style.css';

function AnalyzeButton({ node, show, onAnalyseClick }) {
  const dispatch = useDispatch();
  const { theme } = useSelector(state => state.menu);
  const { metadata, selectedPeriod } = useSelector(state => state.dashboard);
  const clickHandler = () => {
    dispatch(metricSelected(node));
    let indexLabel;
    metadata.forEach((item, index) => {
      if (item.label === node) {
        indexLabel = index;
      }
    });
    onAnalyseClick(indexLabel);
    if (selectedPeriod === 'rf') {
      dispatch(periodChanged('year'));
    }
  };
  return (
    <div className="analyze-btn-container">
      <span
        onClick={clickHandler}
        className={`analyze-btn-${theme}`}
        style={{ opacity: show ? '1' : '0', border: 'none' }}
      >
        <span className={`analyze-btn-icon-container-${theme}`}>
          <img src={AnalyzeIcon} />
        </span>
        Analyze
      </span>
    </div>
  );
}

export default AnalyzeButton;
