import { React } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import Error from '../../views/Error';
import Login from '../../views/Login';
import Dashboard from '../../views/Dashboard';
import Loader from '../Loader';
import * as Api from '../../utils/api';
import store from '../../state/store';
import './style.css';

function App() {
  const loggedin = localStorage.getItem('loggedin');

  if (window.location.pathname == '/callback') {
    Api.loginCallback()
      .then(res => {
        localStorage.setItem('loggedin', 'true');
        window.location = '/dashboard';
      })
      .catch(err => console.log(err));
  }

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              loggedin === 'true' ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/callback" element={<Loader />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={loggedin === 'true' ? <Dashboard /> : <Login />}
          />
          <Route path="/error-403" element={<Error errorCode={403} />} />
          <Route path="/error-404" element={<Error errorCode={404} />} />
          <Route path="/error-500" element={<Error errorCode={500} />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
