import React from 'react';
import { useSelector } from 'react-redux';
import '../style.css';

export const Primary = ({ children }) => {
  const { selectedDashboard } = useSelector(state => state.dashboard);
  return (
    <section
      className="primary"
      style={{ width: selectedDashboard === 'admin' && '100%' }}
    >
      {children}
    </section>
  );
};

export const Secondary = ({ children }) => {
  const { sideMenuCollapsed } = useSelector(state => state.menu);
  return (
    <section
      className="secondary"
      style={{
        width: sideMenuCollapsed ? '25%' : '25%',
        transition: 'all 0.7s ease-in-out',
      }}
    >
      {children}
    </section>
  );
};
