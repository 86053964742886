import { useSelector } from 'react-redux';
import Check from '../../static/svg/check-turquoise.svg';
import CheckOrange from '../../static/svg/check-orange.svg';
import './style.css';

function NavMenuDropdownItem({ active, item, click, color, check, collapsed }) {
  const { selectedDashboard } = useSelector(state => state.dashboard);
  const { adminMenu, theme } = useSelector(state => state.menu);

  return (
    <>
      {(item === 'Clients' || item === 'Users') && (
        <li
          className={
            active
              ? `nav-menu-dropdown-item-active-orange-${theme} nav-menu-dropdown-item-${color}-${theme}`
              : color === 'mediumturquoise'
              ? `nav-menu-dropdown-item-purplenavy-${theme}`
              : `nav-menu-dropdown-item-${color}-${theme}`
          }
          onClick={click}
          style={{ marginLeft: collapsed ? '0rem' : '2rem' }}
        >
          {item}
        </li>
      )}
      {item !== 'Clients' && item !== 'Users' && (
        <li
          className={
            active
              ? `nav-menu-dropdown-item-active-${theme} nav-menu-dropdown-item-${color}-${theme}`
              : color === 'mediumturquoise'
              ? `nav-menu-dropdown-item-purplenavy-${theme}`
              : `nav-menu-dropdown-item-${color}-${theme}`
          }
          onClick={click}
          style={{ marginLeft: collapsed ? '0rem' : '2rem' }}
        >
          {item}
          {/* {check &&
            active &&
            selectedDashboard !== 'admin' &&
            !adminMenu &&
            theme === 'dashwise' && (
              <img src={Check} className="nav-menu-dropdown-item-check" />
            )}
          {check &&
            active &&
            selectedDashboard !== 'admin' &&
            !adminMenu &&
            theme === 'finsights' && (
              <img src={CheckOrange} className="nav-menu-dropdown-item-check" />
            )} */}
        </li>
      )}
    </>
  );
}

export default NavMenuDropdownItem;
