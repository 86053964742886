import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SmallArrow from '../../Icons/SmallArrow';
import {
  viewSelectorViewSelected,
  monthColumnsToggled,
} from '../../../state/reducers/dashboardSlice';
import './style.css';
import ViewSelectorViewOption from '../ViewSelectorViewOption/component';
import Check from '../../../static/svg/check-turquoise.svg';
import CheckFn from '../../../static/svg/check-orange.svg';

function ViewSelectorView({ view, options, click }) {
  const dispatch = useDispatch();
  const { selectedView, selectedViewSelector, selectedPeriod, columnsToHide } =
    useSelector(state => state.dashboard);
  const { theme } = useSelector(state => state.menu);
  const [background, setBackground] = useState(false);

  const handleMouseEnter = () => {
    setBackground(true);
  };

  const handleMouseLeave = () => {
    setBackground(false);
  };

  const viewSelected = () => {
    if (
      view === 'Graph/Table' ||
      view === 'Select Budget' ||
      view === 'Hide Month Columns' ||
      view === 'Show Margins'
    ) {
      return true;
    } else if (
      (selectedView === 'table' && selectedPeriod === 'rf') ||
      (selectedView === 'graphs' && selectedPeriod === 'rf')
    ) {
      return true;
    } else if (
      (selectedView === 'table' && selectedPeriod === 'mulyears') ||
      (selectedView === 'graphs' && selectedPeriod === 'mulyears')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const selectOptions = options.map((option, index) => (
    <ViewSelectorViewOption
      key={index}
      option={option}
      view={view}
      click={click}
    />
  ));

  return (
    <div
      className={
        background
          ? `view-selector-views-view-${theme} view-active-${theme}`
          : `view-selector-views-view-${theme}`
      }
      onClick={
        viewSelected()
          ? () => {
              if (view === 'Hide Month Columns') {
                dispatch(monthColumnsToggled());
              }
              dispatch(viewSelectorViewSelected(view));
            }
          : null
      }
      onMouseEnter={viewSelected() ? handleMouseEnter : null}
      onMouseLeave={viewSelected() ? handleMouseLeave : null}
    >
      {view !== 'Hide Month Columns' && (
        <span
          className={
            viewSelected()
              ? `view-selector-views-view-span-${theme} views-view-span-active-${theme}`
              : `view-selector-views-view-span-${theme}`
          }
        >
          {view}
        </span>
      )}

      {view === 'Hide Month Columns' &&
        columnsToHide.length === 0 &&
        selectedView === 'table' && (
          <>
            <span
              className={
                viewSelected()
                  ? 'view-selector-views-view-span views-view-span-active'
                  : 'view-selector-views-view-span'
              }
            >
              {view}
            </span>
          </>
        )}
      {view === 'Hide Month Columns' &&
        columnsToHide.length > 0 &&
        selectedView === 'table' && (
          <>
            <span
              className={
                viewSelected()
                  ? `view-selector-views-view-span-${theme} views-view-span-active-hide-columns-${theme}`
                  : `view-selector-views-view-span-${theme}`
              }
            >
              {view}
            </span>
            <img src={theme === 'dashwise' ? Check : CheckFn} />
          </>
        )}
      {view !== 'Hide Month Columns' && (
        <SmallArrow
          fill={viewSelected() ? '#3A497D' : '#B0B6CB'}
          className="small-arrow-right"
        />
      )}
      {selectedViewSelector === view && view !== 'Hide Month Columns' && (
        <div className={`view-selector-views-view-options-${theme}`}>
          {selectOptions}
        </div>
      )}
    </div>
  );
}

export default ViewSelectorView;
