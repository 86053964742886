function GraphIcon({ fill }) {
  return (
    <>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            d="M11.0714 3.71429V16.2857H9.92857V3.71429H11.0714ZM9.92857 2C8.96429 2 8.21429 2.78571 8.21429 3.71429V16.2857C8.21429 17.25 8.96429 18 9.92857 18H11.0714C12 18 12.7857 17.25 12.7857 16.2857V3.71429C12.7857 2.78571 12 2 11.0714 2H9.92857ZM5.35714 10.5714V16.2857H4.21429V10.5714H5.35714ZM4.21429 8.85714C3.25 8.85714 2.5 9.64286 2.5 10.5714V16.2857C2.5 17.25 3.25 18 4.21429 18H5.35714C6.28571 18 7.07143 17.25 7.07143 16.2857V10.5714C7.07143 9.64286 6.28571 8.85714 5.35714 8.85714H4.21429ZM15.6429 6H16.7857V16.2857H15.6429V6ZM13.9286 6V16.2857C13.9286 17.25 14.6786 18 15.6429 18H16.7857C17.7143 18 18.5 17.25 18.5 16.2857V6C18.5 5.07143 17.7143 4.28571 16.7857 4.28571H15.6429C14.6786 4.28571 13.9286 5.07143 13.9286 6Z"
            fill={fill}
          />
        </g>
      </svg>
    </>
  );
}

export default GraphIcon;
