import { useSelector } from 'react-redux';
import './style.css';
import {
  formatDateToMonth,
  formatNumberToAmount,
} from '../../../utils/formatters';

const CashflowTooltip = function ({ active, payload, label, cutoff }) {
  const { theme } = useSelector(state => state.menu);
  let values = null;
  if (payload.length > 0) {
    values = payload[0].payload;
  }
  const items = ['opening', 'mutation', 'closing'];
  const itemsActual = items.map((item, index) => {
    return (
      <>
        {payload.length > 0 && (
          <div className="tooltip-actual" key={index}>
            <div className={`tooltip-item-label-dashwise`}>
              {item === 'mutation' && theme === 'dashwise' && (
                <div
                  className="tooltip-color"
                  style={{
                    backgroundColor: values[item] >= 0 ? '#C9F4AA' : '#FFAACF',
                  }}
                ></div>
              )}
              {item === 'mutation' && theme === 'finsights' && (
                <div
                  className="tooltip-color"
                  style={{
                    backgroundColor: values[item] >= 0 ? '#C9F4AA' : '#F5BFAB',
                  }}
                ></div>
              )}
              {item !== 'mutation' && theme === 'dashwise' && (
                <div
                  className="tooltip-color"
                  style={{
                    backgroundColor: '#3D4979',
                  }}
                ></div>
              )}
              {item !== 'mutation' && theme === 'finsights' && (
                <div
                  className="tooltip-color"
                  style={{
                    backgroundColor: '#C3AA8C',
                  }}
                ></div>
              )}

              <span>{item}</span>
            </div>
            <span className={`tooltip-value-dashwise`}>
              {formatNumberToAmount(values[item])}
            </span>
          </div>
        )}
      </>
    );
  });

  return (
    <>
      {active && (
        <div className="tooltip-wrapper">
          <div className={`tooltip-label-dashwise`}>
            {formatDateToMonth(label)}
          </div>

          <div className="tooltip-legend-wrapper">
            <div>
              <div className="tooltip-actual">
                <span></span>
                <span>
                  {new Date(label).getMonth() >= cutoff ? 'BUDGET' : 'ACTUAL'}
                </span>
              </div>
              {itemsActual}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CashflowTooltip;
