import {
  formatDateToYear,
  formatDateToMonth,
  formatDateToShortMonthYear,
  formatDateToMultiYear,
} from '../../utils/formatters';

export const formatters = {
  year: formatDateToYear,
  month: formatDateToMonth,
  ytd: formatDateToShortMonthYear,
  rf: formatDateToShortMonthYear,
  mulyears: formatDateToMultiYear,
};

export const dropdownOptions = [
  {
    label: 'Year',
    key: 'year',
  },
  {
    label: 'Month',
    key: 'month',
  },
  {
    label: 'Year to Date (YTD)',
    key: 'ytd',
  },
  {
    label: 'Re-Forecast (RF)',
    key: 'rf',
  },
  {
    label: 'Multi-Year (Quarters)',
    key: 'mulyears',
  },
];
