import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { metricSelected } from '../../state/reducers/dashboardSlice';

import { COLORS } from '../../utils/constants';

import Button from '../Button';
import Tile from '../Tile';
import AnalyzeIcon from '../../static/svg/analyze-icon.svg';

import './style.css';

function MetricScoreAmount({ value, prefix, unit }) {
  const { theme } = useSelector(state => state.menu);
  return (
    <div className="scorecard-bottomrow-wrapper">
      <div className={`scorecard-value-sign-${theme}`}>
        {value < 0 ? '-' : ''}
      </div>
      <div className={`scorecard-value-number-${theme}`}>
        {`${Math.abs(Math.round(value)).toLocaleString('en-US')}`}
      </div>
      <div className={`scorecard-value-unit-${theme}`}>
        {prefix} {unit}
      </div>
    </div>
  );
}

function MetricScoreLegend({ color, label, active }) {
  const { theme } = useSelector(state => state.menu);
  return (
    <>
      {theme === 'dashwise' && (
        <div className="scorecard-bottomrow-wrapper">
          <div
            className="scorecard-legend-label"
            style={{
              background: active ? color : '#F4F9FE',
              color: active ? '#ffffff' : '#B0B6CB',
            }}
          >
            {label}
          </div>
        </div>
      )}
      {theme === 'finsights' && (
        <div className="scorecard-bottomrow-wrapper">
          <div
            className="scorecard-legend-label"
            style={{
              background: active ? color : '#F4F1E9',
              color: active ? '#ffffff' : '#D8D8D8',
            }}
          >
            {label}
          </div>
        </div>
      )}
    </>
  );
}

function MetricScore(props) {
  const dispatch = useDispatch();
  const { theme } = useSelector(state => state.menu);
  const [hovered, setHovered] = useState(false);
  let textColor;
  if (theme === 'dashwise') {
    textColor = props.isActive ? COLORS.purpleNavy : COLORS.lightGray;
  } else {
    textColor = props.isActive ? COLORS.black : COLORS.medblack;
  }

  const iconColor = props.isActive ? props.color : COLORS.lightGray;

  const handleAnalyseClick = (event, metric) => {
    dispatch(metricSelected(metric));
    props.onAnalyseClick(props.index);
  };

  const handleMetricScoreClick = event => {
    if (
      !event.target.className.includes('scorecard-button-dashwise') ||
      !event.target.className.includes('scorecard-button-finsights')
    ) {
      props.onClick(props.index);
    }
  };

  const handleMouseEnter = index => {
    setHovered(true);
    props.onHover(index);
  };

  const handleMouseLeave = index => {
    setHovered(false);
    props.onHover(index);
  };

  return (
    <>
      {theme === 'dashwise' && (
        <Tile
          background={props.isActive ? '#ffffff' : '#F4F9FE'}
          borderBaseColor={
            props.isActive ? '2px solid #ffffff' : '2px solid #F4F9FE'
          }
          className={theme === 'dashwise' && 'tile-content-metric-score'}
          index={props.index}
          height={props.height}
          color={props.color}
          isActive={props.isActive}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`scorecard-content-${theme}`}
            style={{ color: textColor }}
            onClick={handleMetricScoreClick}
            role="button"
            onKeyDown={() => {}}
            tabIndex={'0'}
          >
            <div className="scorecard-toprow">
              <MetricScoreAmount
                value={props.value}
                prefix={props.prefix}
                unit={props.unit}
              />
            </div>
            <div className="scorecard-bottomrow">
              <div className="scorecard-bottomrow-left">
                <MetricScoreLegend
                  color={iconColor}
                  label={props.label}
                  active={props.isActive}
                />
              </div>
              <div className="scorecard-bottomrow-right">
                {hovered &&
                  props.isActive &&
                  (props.type == 'bar' ||
                    (props.type == 'line' &&
                      props.label == 'Gross Profit Margin')) && (
                    <Button
                      className={`scorecard-button-${theme}`}
                      onClick={e => {
                        handleAnalyseClick(e, props.label);
                      }}
                    >
                      <div className="scorecard-button-content">
                        <span className="scorecard-button-icon-container">
                          <img src={AnalyzeIcon} />
                        </span>
                        <span className="scorecard-button-text">Analyse</span>
                        <span className="scorecard-button-separator"></span>
                      </div>
                    </Button>
                  )}
              </div>
            </div>
          </div>
        </Tile>
      )}
      {theme === 'finsights' && (
        <Tile
          background={props.isActive ? '#ffffff' : '#F4F1E9'}
          borderBaseColor="2px solid #F4F1E9"
          className={theme === 'finsights' && 'tile-content-fn-metric-score'}
          index={props.index}
          height={props.height}
          color={props.color}
          isActive={props.isActive}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={`scorecard-content-${theme}`}
            style={{ color: textColor }}
            onClick={handleMetricScoreClick}
            role="button"
            onKeyDown={() => {}}
            tabIndex={'0'}
          >
            <div className="scorecard-toprow">
              <MetricScoreAmount
                value={props.value}
                prefix={props.prefix}
                unit={props.unit}
              />
            </div>
            <div className="scorecard-bottomrow">
              <div className="scorecard-bottomrow-left">
                <MetricScoreLegend
                  color={iconColor}
                  label={props.label}
                  active={props.isActive}
                />
              </div>
              <div className="scorecard-bottomrow-right">
                {hovered &&
                  props.isActive &&
                  (props.type == 'bar' ||
                    (props.type == 'line' &&
                      props.label == 'Gross Profit Margin')) && (
                    <Button
                      className={`scorecard-button-${theme}`}
                      onClick={e => {
                        handleAnalyseClick(e, props.label);
                      }}
                    >
                      <div className="scorecard-button-content">
                        <span className="scorecard-button-icon-container">
                          <img src={AnalyzeIcon} />
                        </span>
                        <span className="scorecard-button-text">Analyse</span>
                        <span className="scorecard-button-separator"></span>
                      </div>
                    </Button>
                  )}
              </div>
            </div>
          </div>
        </Tile>
      )}
    </>
  );
}

export default MetricScore;
