import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as API from '../../utils/api';
import Arrow from '../Arrow';
import Button from '../Button';
import NavMenu from '../NavMenu';
import {
  getUserData,
  dropdownOptionClicked,
  clientsDataSet,
  periodsDataSet,
  divisionsSelected,
  divisionDataSet,
  menuClicked,
  getPeriodsData,
  logoSelected,
  themeSelected,
} from '../../state/reducers/menuSlice';
import {
  divisionSelected,
  getBudgetScenarios,
  getData,
  isLoadingSet,
  periodChanged,
} from '../../state/reducers/dashboardSlice';
import EbitwiseLogo from '../../static/img/ebitwise-logo.png';
import MenuIcon from '../../static/svg/menu-icon.svg';
import MenuIconFn from '../../static/svg/menu-icon-fn.svg';
import AvatarIcon from '../../static/svg/avatar.svg';
import AvatarIconFN from '../../static/svg/avatar-fn.svg';
import ClientsIcon from '../../static/svg/clients.svg';
import ClientsIconFn from '../../static/svg/clients-fn.svg';
import AdminIcon from '../../static/svg/admin-icon.svg';
import AdminIconFN from '../../static/svg/admin-icon-fn.svg';
import ConsolidationIcon from '../../static/svg/consolidation.svg';
import EbitLogo from '../../static/svg/small-logo.svg';
import FNLogo from '../../static/svg/fn-logo-small.svg';
import FNLogoSmall from '../../static/svg/logo-fn-small.svg';
import { ReactComponent as CheckMark } from '../../static/svg/check.svg';

import {
  USER_OPTIONS,
  ADMIN_OPTIONS,
  MENU_OPTIONS,
  AUTHORIZED_ROLES,
  AUTHORIZED_ADMIN_ROLES,
} from './constants';

import './style.css';
import Checkbox from '../Checkbox';

const NavDropdown = ({
  className,
  options,
  selectedIndex,
  onClick,
  type,
  footer,
}) => {
  const dispatch = useDispatch();
  const { selectedDivisions, divisions, theme } = useSelector(
    state => state.menu
  );
  const [show, setShow] = useState(false);

  const clientOptions = [...options];
  if (type !== 'footer') {
    clientOptions.push('Consolidated');
  }

  const handleSubmenuOption = option => {
    const divisionsTemp = [...selectedDivisions];
    if (!divisionsTemp.includes(divisions[option].code)) {
      divisionsTemp.push(divisions[option].code);
    } else {
      const index = divisionsTemp.indexOf(divisions[option].code);
      divisionsTemp.splice(index, 1);
    }
    dispatch(divisionsSelected(divisionsTemp));
  };

  return (
    <>
      <ul className={className}>
        {clientOptions.map((item, index) => (
          <li key={index}>
            <Button onClick={() => onClick(index, item)}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: footer ? 'flex-start' : 'space-between',
                  alignItems: 'center',
                }}
              >
                <span
                  className={
                    index == selectedIndex
                      ? `nav-header-dropdown-item-text-active-${theme}`
                      : `nav-header-dropdown-item-text-${theme}`
                  }
                >
                  {item}
                </span>

                {footer && item === 'Select theme' && (
                  <img
                    src={theme === 'dashwise' ? EbitLogo : FNLogo}
                    width={16}
                    height={16}
                    style={{ marginLeft: '0.25rem' }}
                  />
                )}
                {footer && item === 'Select theme' && (
                  <span style={{ margin: '0 0.25rem' }}>|</span>
                )}
                {footer && item === 'Select theme' && (
                  <img
                    src={theme === 'dashwise' ? FNLogo : EbitLogo}
                    width={16}
                    height={16}
                  />
                )}

                <span className={`nav-header-dropdown-item-icon-${theme}`}>
                  {index == selectedIndex && <CheckMark />}
                </span>
              </div>
            </Button>
          </li>
        ))}
        {type !== 'footer' && (
          <div className="nav-header-dropdown-item-consolidation">
            <Button onClick={() => setShow(!show)}>
              <img
                src={ConsolidationIcon}
                className="nav-header-dropdown-item-consolidation-icon"
                alt="consolidation icon"
              />
              <span className="nav-header-dropdown-item-text">
                Select Consolidation Entities
              </span>
            </Button>
            {show && (
              <ul
                className={`nav-header-dropdown-consolidation-sub-menu-${theme}`}
              >
                {options.map((option, index) => (
                  <li
                    key={index}
                    className="nav-header-dropdown-consolidation-sub-menu-option"
                  >
                    <Button key={index} onClick={null}>
                      <Checkbox
                        item={option}
                        onChange={() => handleSubmenuOption(index)}
                        checked={selectedDivisions.includes(
                          divisions[index].code
                        )}
                      />
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </ul>
    </>
  );
};

const Nav = () => {
  const dispatch = useDispatch();
  const {
    clients,
    divisions,
    selectedDivisions,
    username,
    selectedClientIndex,
    selectedIndex,
    isDropdownOpenHeader,
    isDropdownOpenFooter,
    roleId,
    logo,
    sideMenuCollapsed,
    theme,
    options,
  } = useSelector(state => state.menu);
  const { selectedDivision, selectedDashboard, selectedPeriod } = useSelector(
    state => state.dashboard
  );

  useEffect(() => {
    dispatch(getUserData());
    getAlldata();
  }, []);

  const getAlldata = async () => {
    const clients = await API.getClients();
    dispatch(clientsDataSet(clients.data.data));

    // const storedClientIndex = clients.data.data.findIndex(item => {
    //   return item.id === parseInt(localStorage.getItem('selectedClient'));
    // });

    let divisions;
    // if (storedClientIndex >= 0) {
    //   divisions = clients.data.data[storedClientIndex].divisions;
    //   dispatch(
    //     clientSelected(parseInt(localStorage.getItem('selectedClient')))
    //   );
    //   dispatch(clientIndexSelected(storedClientIndex));
    //   dispatch(logoSelected(clients.data.data[storedClientIndex].logo_url));
    //   dispatch(divisionDataSet(divisions));
    //   const storedDivision = localStorage.getItem('selectedDivision');
    //   if (storedDivision) {
    //     dispatch(divisionSelected(storedDivision));
    //     dispatch(
    //       dropdownOptionClicked(
    //         parseInt(localStorage.getItem('selectedDivisionIndex'))
    //       )
    //     );
    //     dispatch(divisionsSelected(storedDivision.split(',')));
    //   } else {
    //     if (divisions[0]) {
    //       dispatch(divisionSelected(divisions[0].code));
    //     } else {
    //       dispatch(isLoadingSet(false));
    //     }
    //   }
    // } else {
    //   dispatch(logoSelected(clients.data.data[0].logo_url));
    //   divisions = clients.data.data[0].divisions;

    //   localStorage.setItem('selectedClient', clients.data.data[0].id);
    //   localStorage.setItem('selectedDivisionIndex', '0');
    //   dispatch(divisionDataSet(divisions));
    //   dispatch(divisionSelected(divisions[1].code));
    // }

    // THIS IS TEMP FROM HERE TO
    dispatch(logoSelected(clients.data.data[0].logo_url));
    divisions = clients.data.data[0].divisions;
    dispatch(divisionDataSet(divisions));
    dispatch(divisionSelected(divisions[0].code));
    // HERE

    const periods = await API.getSelectorOptions(divisions[0].code);
    dispatch(periodsDataSet(periods.data.data));
    dispatch(getBudgetScenarios());
    dispatch(getData());
  };

  const handleClickDropdownHeader = () => {
    dispatch(menuClicked('isDropdownOpenHeader'));
  };

  const handleSelectDropdownOption = (index, item) => {
    if (item === 'Consolidated') {
      dispatch(dropdownOptionClicked(index));
      if (selectedDivisions.length !== 0) {
        let tempDivisions = '';
        selectedDivisions.forEach(
          item => (tempDivisions = tempDivisions + `${item},`)
        );
        dispatch(divisionSelected(tempDivisions.slice(0, -1)));
        dispatch(getPeriodsData());
        dispatch(getBudgetScenarios());
        dispatch(getData());
        dispatch(menuClicked());
      } else {
        return;
      }
    } else if (selectedDivision === divisions[index].code) {
      return;
    } else {
      dispatch(dropdownOptionClicked(index));
      dispatch(divisionSelected(divisions[index].code));
      dispatch(getPeriodsData());
      dispatch(getBudgetScenarios());
      dispatch(getData());
      dispatch(menuClicked());
    }
  };

  const handleClickDropdownFooter = () => {
    dispatch(menuClicked('isDropdownOpenFooter'));
  };

  // let clientName;
  // if (localStorage.getItem('selectedClient')) {
  //   clientName = clients.find(item => {
  //     return item.id === parseInt(localStorage.getItem('selectedClient'));
  //   });
  //   if (clientName) {
  //     clientName = clientName.name;
  //   } else {
  //     clientName = '';
  //   }
  // } else {
  //   clientName = clients.length > 0 ? clients[selectedClientIndex].name : '';
  // }

  const clientName =
    clients.length > 0 ? clients[selectedClientIndex].name : '';

  return (
    <nav className={`nav-${theme}`}>
      <header>
        <div className="nav-header-icon">
          {selectedDashboard !== 'admin' && (
            <div
              className={
                logo
                  ? 'nav-header-icon-container'
                  : 'nav-header-icon-container-no-logo'
              }
            >
              {logo && <img src={logo} className="nav-header-icon-image" />}
            </div>
          )}
        </div>
        <div
          className="nav-header-content"
          style={{
            opacity: sideMenuCollapsed ? '0' : '1',
            transition: 'opacity 0.4s ease-in-out',
          }}
        >
          {selectedDashboard !== 'admin' && (
            <div className={`nav-header-title-${theme}`}>{clientName}</div>
          )}
          {selectedDashboard === 'admin' && (
            <h2 style={{ fontWeight: '700' }}>Admin</h2>
          )}
          {selectedDashboard !== 'admin' && (
            <div className={`nav-header-subtitle-${theme}`}>
              <span>Powered by</span>
              <img
                src={theme === 'dashwise' ? EbitwiseLogo : FNLogoSmall}
                alt="logo"
                height={17}
                style={{ marginLeft: '0.25rem' }}
              />
            </div>
          )}
        </div>
        <div
          className={`nav-header-button-${theme}`}
          style={{
            right: sideMenuCollapsed ? '193px' : '4px',
            transition: 'all 0.7s ease-in-out',
          }}
        >
          {selectedDashboard !== 'admin' && (
            <Button
              className="nav-header-button-container"
              onClick={handleClickDropdownHeader}
            >
              <Arrow
                shape="arrowhead"
                direction={isDropdownOpenHeader ? 'up' : 'down'}
              />
            </Button>
          )}
        </div>
      </header>
      {isDropdownOpenHeader && (
        <NavDropdown
          className={
            sideMenuCollapsed
              ? `nav-header-dropdown-collapsed-${theme}`
              : `nav-header-dropdown-${theme}`
          }
          options={divisions.map(division => division.description)}
          selectedIndex={selectedIndex}
          onClick={handleSelectDropdownOption}
        />
      )}

      {/* Navigation Menu */}

      {selectedDashboard !== 'admin' && (
        <NavMenu
          title="Dashboards"
          menuItems={MENU_OPTIONS}
          icon={theme === 'dashwise' ? MenuIcon : MenuIconFn}
          background={'lightturquoise'}
          color={'mediumturquoise'}
        />
      )}
      {AUTHORIZED_ROLES.includes(roleId) && selectedDashboard !== 'admin' && (
        <div
          className="nav-clients-menu-container"
          style={{
            height:
              !AUTHORIZED_ADMIN_ROLES.includes(roleId) && 'calc(100% - 280px)',
            maxHeight:
              AUTHORIZED_ADMIN_ROLES.includes(roleId) && 'calc(100% - 400px)',
          }}
        >
          <NavMenu
            title="Clients"
            menuItems={clients}
            icon={theme === 'dashwise' ? ClientsIcon : ClientsIconFn}
            background={'gray'}
            color={'purplenavy'}
            check={true}
          />
        </div>
      )}

      {AUTHORIZED_ADMIN_ROLES.includes(roleId) && (
        <div
          className="nav-clients-menu-container"
          style={{ marginTop: selectedDashboard === 'admin' && '3rem' }}
        >
          <NavMenu
            title="Admin"
            menuItems={ADMIN_OPTIONS}
            icon={theme === 'dashwise' ? AdminIcon : AdminIconFN}
            background={'lightorange'}
            color={'orange'}
            check={true}
          />
        </div>
      )}

      {/* Footer */}
      <footer>
        <div
          className={`nav-footer-icon-${theme}`}
          onClick={sideMenuCollapsed ? handleClickDropdownFooter : null}
          style={{ cursor: sideMenuCollapsed && 'pointer' }}
        >
          <div className="nav-footer-icon-container">
            <img src={theme === 'dashwise' ? AvatarIcon : AvatarIconFN} />
          </div>
        </div>
        <div
          className="nav-footer-content"
          style={{
            opacity: sideMenuCollapsed ? '0' : '1',
            transition: 'opacity 0.4s ease-in-out',
          }}
        >
          <div className={`nav-footer-text-${theme}`}>{username}</div>
        </div>
        <div
          className="nav-footer-button"
          style={{
            right: sideMenuCollapsed ? '185px' : '0px',
            transition: 'all 0.7s ease-in-out',
          }}
        >
          {!sideMenuCollapsed && (
            <Button
              className="nav-header-button-container"
              onClick={handleClickDropdownFooter}
            >
              <Arrow
                shape="arrowhead"
                direction={isDropdownOpenFooter ? 'up' : 'down'}
              />
            </Button>
          )}
        </div>
      </footer>
      {isDropdownOpenFooter && (
        <NavDropdown
          className={
            sideMenuCollapsed
              ? `nav-footer-dropdown-collapsed-${theme}`
              : `nav-footer-dropdown-${theme}`
          }
          options={USER_OPTIONS.map(user => user.name)}
          onClick={async index => {
            if (index === 0) {
              if (theme === 'dashwise') {
                dispatch(isLoadingSet(true));
                dispatch(themeSelected('finsights'));
                handleClickDropdownFooter();
                if (selectedDashboard !== 'cashflow') {
                  getAlldata();
                }
                dispatch(isLoadingSet(false));
              } else {
                dispatch(isLoadingSet(true));
                dispatch(themeSelected('dashwise'));
                handleClickDropdownFooter();
                if (selectedDashboard !== 'cashflow') {
                  getAlldata();
                }
                dispatch(isLoadingSet(false));
              }
            } else {
              handleClickDropdownFooter();
              dispatch(isLoadingSet(true));
              await API.logOut();
              window.location = '/login';
            }
          }}
          type="footer"
          footer={true}
        />
      )}
    </nav>
  );
};

export default Nav;
