// Tile is used to wrap components for with a standard style.
import React, { Component } from 'react';
import './style.css';

class Tile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
    if (this.props.onMouseEnter) {
      this.props.onMouseEnter(this.props.index);
    }
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave(null);
    }
  };

  render() {
    // if height is not defined, set it to 1 (100%) by default
    const height = this.props.height ? this.props.height : 1;
    const zIndex = this.props.zIndex ? this.props.zIndex : 0;
    return (
      <>
        <div
          className="tile-wrapper"
          style={{
            height: height * 100 + '%',
            zIndex: zIndex,
          }}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <div
            className={`tile-content ${this.props.className}`}
            style={{
              backgroundColor: this.props.background,
              border:
                this.state.isHovered && this.props.isActive
                  ? `2px solid ${this.props.color}`
                  : this.props.className === 'tile-button-back'
                  ? '2px solid white'
                  : this.props.borderBaseColor
                  ? this.props.borderBaseColor
                  : '2px solid white',
            }}
          >
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}

export default Tile;
