import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { savingClientsSet, usersSet } from '../../state/reducers/adminSlice';
import SelectIcon from '../../static/svg/select-arrow.svg';
import * as API from '../../utils/api';
import './style.css';

function UserTableClientSelector({ client, id }) {
  const dispatch = useDispatch();
  const { clients } = useSelector(state => state.admin);

  const [show, setShow] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});

  useEffect(() => {
    const clientTemp = clients.find(item => item.id == client);
    if (clientTemp) {
      setSelectedClient({
        id: clientTemp.id,
        name: clientTemp.name,
        url: clientTemp.url,
      });
    }
  }, []);

  const showHandler = () => {
    setShow(!show);
  };

  const handleClientSelection = async client => {
    setSelectedClient({
      id: client.id,
      name: client.name,
      url: client.url,
    });
    try {
      dispatch(savingClientsSet(id));
      await API.editUserClients(id, JSON.stringify([client.id]));
      const response = await API.getUsersAdmin();
      dispatch(usersSet(response.data.data));
      dispatch(savingClientsSet(null));
    } catch (e) {
      console.log(e);
      dispatch(savingClientsSet(null));
    }
  };

  const options = clients.map(client => (
    <div
      className="client-selector-option"
      onClick={() => handleClientSelection(client)}
    >
      <img src={client.url} width={18} /> {client.name}
    </div>
  ));

  return (
    <div className="client-selector" onClick={showHandler}>
      {selectedClient && (
        <img src={selectedClient.url} width={18} height={18} />
      )}
      {selectedClient && selectedClient.name}
      <img src={SelectIcon} width={18} height={18} />
      {show && <div className="client-selector-options">{options}</div>}
    </div>
  );
}

export default UserTableClientSelector;
