import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {
  rowEditIndexSelected,
  deleteModalClicked,
  clientsSet,
  deleteClient,
  deleteUser,
  clientEditMenuIndexSet,
} from '../../state/reducers/adminSlice';
import * as API from '../../utils/api';
import PlayArrow from '../../static/svg/play_arrow.svg';
import SyncIcon from '../../static/svg/sync.svg';
import Dots from '../../static/svg/dots.svg';
import './style.css';

function ClientsTableRow({ data, first }) {
  const dispatch = useDispatch();
  const { rowEditIndex, clientEditMenuIndex } = useSelector(
    state => state.admin
  );
  const [loading, setLoading] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState(data.name);
  const [logo, setLogo] = useState(data.url);
  const [exactUsername, setExactUsername] = useState(data.username);

  const initSync = async () => {
    setLoading(true);
    try {
      await API.updateClientData(data.id);
      setLoading(false);
    } catch (e) {
      NotificationManager.error('Something went wrong');
      setLoading(false);
    }
  };

  const updateClient = async () => {
    setLoadingClient(true);
    try {
      await API.updatePipelines(data.id);
      setLoadingClient(false);
    } catch (e) {
      NotificationManager.error('Something went wrong');
      setLoadingClient(false);
    }
  };

  const handleMouseEnter = () => {
    setShowMenu(true);
  };

  const handleMouseLeave = () => {
    setShowMenu(false);
  };

  const handleClick = () => {
    setShowSubMenu(true);
    dispatch(clientEditMenuIndexSet(data.id));
  };

  const handleEdit = () => {
    setShowSubMenu(false);
    setEdit(true);
    dispatch(rowEditIndexSelected(data.id));
  };

  const handleDelete = async () => {
    dispatch(deleteModalClicked(true));
    dispatch(rowEditIndexSelected(null));
    dispatch(deleteUser(null));
    dispatch(deleteClient(data.id));
    setShowSubMenu(false);
  };

  const handleSave = async () => {
    setEdit(false);
    dispatch(rowEditIndexSelected(null));
    setSaving(true);
    await API.editClient(data.id, name, logo, exactUsername);
    const response = await API.getClientsAdmin();
    dispatch(clientsSet(response.data.data));
    setSaving(false);
  };

  const handleCancel = () => {
    setEdit(false);
  };

  return (
    <div className="clients-table-row" style={{ borderTop: first && 'none' }}>
      <NotificationContainer />
      <div
        className="clients-table-cell-0"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {showSubMenu && clientEditMenuIndex === data.id && (
          <div className="client-table-row-menu">
            <div className="client-table-row-menu-option" onClick={handleEdit}>
              Edit Client
            </div>
            <div
              className="client-table-row-menu-option"
              onClick={handleDelete}
            >
              Delete Client
            </div>
          </div>
        )}
        {showMenu && (
          <img
            src={Dots}
            className="clients-table-cell-menu"
            onClick={handleClick}
          />
        )}
        <img src={data.url} width={30} />
        <input
          value={name}
          onChange={e => setName(e.target.value)}
          readOnly={!edit && rowEditIndex !== data.id}
          className="client-table-cell-input"
        />
      </div>
      <div className="clients-table-cell-1">{data.id}</div>
      <div className="clients-table-cell-2">
        <input
          value={logo}
          onChange={e => setLogo(e.target.value)}
          readOnly={!edit && rowEditIndex !== data.id}
          className="client-table-cell-input input-url"
        />
      </div>
      <div className="clients-table-cell-3">
        <input
          value={exactUsername}
          onChange={e => setExactUsername(e.target.value)}
          readOnly={!edit && rowEditIndex !== data.id}
          className="client-table-cell-input"
        />
        {saving && (
          <button className="client-table-cell-save">
            <img src={SyncIcon} className="client-table-row-loading" />
          </button>
        )}
        {edit && rowEditIndex === data.id && (
          <button className="client-table-cell-save" onClick={handleSave}>
            Save
          </button>
        )}
        {edit && rowEditIndex === data.id && (
          <button className="client-table-cell-save" onClick={handleCancel}>
            Cancel
          </button>
        )}

        <button
          className="clients-table-row-btn"
          onClick={updateClient}
          disabled={loadingClient}
        >
          {loadingClient && (
            <img
              src={SyncIcon}
              width={20}
              className="client-table-row-loading"
            />
          )}
          {!loadingClient && <img src={PlayArrow} width={30} />}
          Update client
        </button>
        <button
          className="clients-table-row-btn"
          onClick={initSync}
          disabled={loading}
        >
          {loading && (
            <img
              src={SyncIcon}
              width={20}
              className="client-table-row-loading"
            />
          )}
          {!loading && <img src={PlayArrow} width={30} />}
          Initial Sync
        </button>
      </div>
    </div>
  );
}

export default ClientsTableRow;
