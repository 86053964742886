import { useSelector, useDispatch } from 'react-redux';
import {
  dashboardSelected,
  getData,
} from '../../../state/reducers/dashboardSlice';
import { getClientsData, getUserData } from '../../../state/reducers/menuSlice';
import Arrow from '../../Arrow';
import Tile from '../../Tile';
import Button from '../component';

import './style.css';

function ButtonBackAdmin() {
  const { theme } = useSelector(state => state.menu);
  const dispatch = useDispatch();
  const back = async () => {
    dispatch(dashboardSelected(0));
    dispatch(getClientsData());
    dispatch(getUserData());
    dispatch(getData());
  };

  return (
    <div className="button-back-container">
      <Tile
        className={
          theme === 'dashwise' ? 'tile-button-back' : 'tile-button-back-fn'
        }
      >
        <Button className={`button-back-${theme}`} onClick={back}>
          <Arrow shape="arrowhead" />
          Go Back
        </Button>
      </Tile>
    </div>
  );
}

export default ButtonBackAdmin;
