import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { menuClicked } from '../../state/reducers/menuSlice';
import Button from '../Button';
import Arrow from '../Arrow';
import AscIcon from '../../static/svg/asc.svg';
import AscIconFn from '../../static/svg/asc-fn.svg';
import { formatDataKeyToLabel } from '../../utils/formatters';

import './style.css';

const Dropdown = ({ label, defaultIndex, options, onChange }) => {
  const dispatch = useDispatch();
  const { theme } = useSelector(state => state.menu);
  const { selectedDashboard, selectedGroup, selectedOrder } = useSelector(
    state => state.dashboard
  );
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(defaultIndex);
  const [buttonText, setButtonText] = useState('None');

  useEffect(() => {
    if (label === 'Group by') {
      if (selectedGroup) {
        setButtonText(formatDataKeyToLabel(selectedGroup));
      }
      setSelectedIndex(options.indexOf(selectedGroup));
    }

    if (label === 'Order by') {
      if (selectedOrder) {
        setButtonText(formatDataKeyToLabel(selectedOrder));
      }
      setSelectedIndex(options.indexOf(selectedOrder));
    }
  }, []);

  const handleOpen = () => {
    dispatch(menuClicked());
    setOpen(!open);
  };

  const handleSelect = index => {
    setSelectedIndex(index);
    setOpen(false);
    onChange(options[index]);
  };

  return (
    <>
      <div className="dropdown">
        <div
          className={
            selectedDashboard === 'profit-and-loss'
              ? `dropdown-label-${theme}`
              : 'dropdown-label-inactive'
          }
        >
          {label}:
        </div>
        <div
          className={
            selectedDashboard === 'profit-and-loss'
              ? `dropdown-container-${theme}`
              : 'dropdown-container-inactive'
          }
        >
          <Button
            onClick={
              selectedDashboard === 'profit-and-loss' ? handleOpen : null
            }
            className="dropdown-button"
          >
            {/* {options[selectedIndex] == ''
              ? 'None'
              : formatDataKeyToLabel(options[selectedIndex])} */}
            <div>
              {buttonText === 'Amount Ascending' && (
                <img
                  src={theme === 'dashwise' ? AscIcon : AscIconFn}
                  className="dropdown-asc"
                />
              )}
              {buttonText === 'Amount Descending' && (
                <img
                  src={theme === 'dashwise' ? AscIcon : AscIconFn}
                  className="dropdown-desc"
                />
              )}
              {buttonText}
            </div>
            <Arrow
              shape="arrowhead-black-margin"
              direction={open ? 'up' : 'down'}
            />
          </Button>
          {open && (
            <div className="dropdown-list">
              {options.map((option, index) => {
                return (
                  index !== selectedIndex && (
                    <Button
                      key={index}
                      index={index}
                      onClick={() => handleSelect(index)}
                    >
                      {option === 'amountAscending' && (
                        <img
                          src={theme === 'dashwise' ? AscIcon : AscIconFn}
                          className="dropdown-asc-small"
                        />
                      )}
                      {option === 'amountDescending' && (
                        <img
                          src={theme === 'dashwise' ? AscIcon : AscIconFn}
                          className="dropdown-desc-small"
                        />
                      )}
                      {option == '' ? 'None' : formatDataKeyToLabel(option)}
                    </Button>
                  )
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
