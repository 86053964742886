import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { menuClicked } from '../../state/reducers/menuSlice';
import Arrow from '../Arrow';

import './style.css';

function NavMenuDropdown({ children, icon, title, background, color }) {
  const dispatch = useDispatch();
  const { clientsMenu, dashboardsMenu, sideMenuCollapsed, adminMenu, theme } =
    useSelector(state => state.menu);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (title === 'Clients') {
      setOpen(clientsMenu);
    }

    if (title === 'Dashboards') {
      setOpen(dashboardsMenu);
    }

    if (title === 'Admin') {
      setOpen(adminMenu);
    }
  }, [clientsMenu, dashboardsMenu, adminMenu]);

  const contentEl = useRef();
  const handleToggle = () => {
    if (title === 'Clients') {
      dispatch(menuClicked('clientsMenu'));
      setOpen(clientsMenu);
    }

    if (title === 'Dashboards') {
      dispatch(menuClicked('dashboardsMenu'));
      setOpen(dashboardsMenu);
    }

    if (title === 'Admin') {
      dispatch(menuClicked('adminMenu'));
      setOpen(adminMenu);
    }
  };

  return (
    <>
      <ul className={`nav-menu-dropdown nav-menu-${background}-${theme}`}>
        <div
          className={`nav-menu-dropdown-label-${theme} nav-menu-dropdown-label-${color}-${theme}`}
        >
          <span></span>
          {icon && (
            <span
              className={`nav-menu-dropdown-label-icon-${theme}`}
              onClick={sideMenuCollapsed ? handleToggle : null}
              style={{ cursor: sideMenuCollapsed && 'pointer' }}
            >
              <img src={icon} />
            </span>
          )}
          <span
            className={`nav-menu-title-${theme}`}
            style={{
              opacity: sideMenuCollapsed ? '0' : '1',
              transition: 'opacity 0.7s ease-in-out',
            }}
          >
            {title}
          </span>
          <span
            onClick={handleToggle}
            className="nav-menu-dropdown-label-arrow"
            style={{
              right: sideMenuCollapsed ? '205px' : '16px',
              transition: 'all 0.7s ease-in-out',
            }}
          >
            {theme === 'dashwise' && !sideMenuCollapsed && (
              <Arrow
                shape={theme === 'dashwise' ? 'arrowhead' : 'arrowhead-black'}
                direction={
                  sideMenuCollapsed
                    ? open
                      ? 'left'
                      : 'right'
                    : open
                    ? 'up'
                    : 'down'
                }
              />
            )}
            {theme === 'finsights' && !sideMenuCollapsed && (
              <Arrow
                shape={
                  color === 'purplenavy' ? 'arrowhead-black' : 'arrowhead-black'
                }
                direction={
                  sideMenuCollapsed
                    ? open
                      ? 'left'
                      : 'right'
                    : open
                    ? 'up'
                    : 'down'
                }
              />
            )}
          </span>
        </div>
        {sideMenuCollapsed ? (
          <>
            {open && (
              <div
                className={
                  title === 'Dashboards'
                    ? `nav-menu-collapsed-show-options-clients-${theme}`
                    : title === 'Clients'
                    ? `nav-menu-collapsed-show-options-${theme}`
                    : `nav-menu-collapsed-show-options-admin-${theme}`
                }
              >
                {children}
              </div>
            )}
          </>
        ) : (
          <div
            ref={contentEl}
            className="nav-menu-dropdown-transition"
            style={{
              maxHeight:
                open && contentEl.current
                  ? contentEl.current.scrollHeight
                  : '0rem',
            }}
          >
            {children}
          </div>
        )}
      </ul>
    </>
  );
}

export default NavMenuDropdown;
