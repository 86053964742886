const Button = props => {
  const { children, ...otherProps } = props;
  return (
    <div {...otherProps} role="button" onKeyDown={() => {}} tabIndex={'0'}>
      {children}
    </div>
  );
};

export default Button;
