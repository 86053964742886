import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  viewSwitched,
  getData,
  viewSelectorOptionSelected,
  viewSelectorOptionSelectedVar,
  marginAmountSelected,
} from '../../../state/reducers/dashboardSlice';
import GraphIcon from '../../Icons/GraphIcon';
import TableIcon from '../../Icons/TableIcon';
import check from '../../../static/svg/check-green.svg';
import checkOrange from '../../../static/svg/check-orange.svg';
import './style.css';

function ViewSelectorViewOption({ option, view, click }) {
  const dispatch = useDispatch();
  const { selectedView, budget, budgetVar, marginAmount } = useSelector(
    state => state.dashboard
  );
  const { theme } = useSelector(state => state.menu);
  const [background, setBackground] = useState(false);

  const handleClick = option => {
    if (option === 'Graph') {
      dispatch(viewSwitched('graphs'));
      dispatch(getData());
    } else if (option === 'Table') {
      dispatch(viewSwitched('table'));
      dispatch(getData());
    } else if (option === 'Margin') {
      dispatch(marginAmountSelected(true));
    } else if (option === 'Amount') {
      dispatch(marginAmountSelected(false));
    } else {
      if (view === 'Select Budget') {
        dispatch(viewSelectorOptionSelected(option));
      }
      if (view === 'Re-Forecast Budget') {
        dispatch(viewSelectorOptionSelectedVar(option));
      }

      dispatch(getData());
    }
    click();
  };

  const checkView = () => {
    if (selectedView === 'graphs') {
      return 'Graph';
    } else {
      return 'Table';
    }
  };

  const handleMouseEnter = () => {
    if (option !== 'No Budget') {
      setBackground(true);
    }
  };

  const handleMouseLeave = () => {
    setBackground(false);
  };

  return (
    <div
      className={
        background
          ? `view-selector-views-view-option-${theme} view-option-active-${theme}`
          : `view-selector-views-view-option-${theme}`
      }
      onClick={option !== 'No Budget' ? () => handleClick(option) : null}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span
        className={
          (budget === option && view === 'Select Budget') ||
          checkView() === option ||
          (budgetVar === option && view === 'Re-Forecast Budget') ||
          (option === 'Margin' &&
            marginAmount.includes('Gross Profit Margin')) ||
          (option === 'Amount' && !marginAmount.includes('Gross Profit Margin'))
            ? `view-selector-views-view-option-span-${theme} view-option-span-active-${theme}`
            : `view-selector-views-view-option-span-${theme}`
        }
      >
        {option === 'Graph' && theme === 'dashwise' && (
          <GraphIcon fill={checkView() === option ? '#64DACF' : '#B0B6CB'} />
        )}
        {option === 'Table' && theme === 'dashwise' && (
          <TableIcon fill={checkView() === option ? '#64DACF' : '#B0B6CB'} />
        )}
        {option === 'Graph' && theme === 'finsights' && (
          <GraphIcon
            fill={checkView() === option ? '#FF784E' : 'rgba(29, 30, 20, 0.5)'}
          />
        )}
        {option === 'Table' && theme === 'finsights' && (
          <TableIcon
            fill={checkView() === option ? '#FF784E' : 'rgba(29, 30, 20, 0.5)'}
          />
        )}
        {option}
      </span>{' '}
      {budget === option && view === 'Select Budget' && (
        <img src={theme === 'dashwise' ? check : checkOrange} alt="checkmark" />
      )}
      {budgetVar === option && view === 'Re-Forecast Budget' && (
        <img src={theme === 'dashwise' ? check : checkOrange} alt="checkmark" />
      )}
      {option === 'Amount' && !marginAmount.includes('Gross Profit Margin') && (
        <img src={theme === 'dashwise' ? check : checkOrange} alt="checkmark" />
      )}
      {option === 'Margin' && marginAmount.includes('Gross Profit Margin') && (
        <img src={theme === 'dashwise' ? check : checkOrange} alt="checkmark" />
      )}
      {checkView() === option && (
        <img src={theme === 'dashwise' ? check : checkOrange} alt="view-icon" />
      )}
    </div>
  );
}

export default ViewSelectorViewOption;
