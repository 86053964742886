import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from './reducers/dashboardSlice';
import menuReducer from './reducers/menuSlice';
import adminReducer from './reducers/adminSlice';

// Sets up the store, holds the state for the application
export default configureStore({
  reducer: {
    // the reducer for the dashboard, its state, reducers
    dashboard: dashboardReducer,
    menu: menuReducer,
    admin: adminReducer,
  },
});
