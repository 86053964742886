export const calculateGrossProfitMargin = data => {
  let revenue = 0;
  let cogs = 0;
  data.forEach(item => {
    revenue = revenue + item['Revenue'];
    cogs = cogs + item['COGS'];
  });

  return ((revenue + cogs) / revenue) * 100;
};
