import React, { Component } from 'react';

import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import EbitLogo from '../../static/img/logo-ebit.png';
import GoogleLogo from '../../static/svg/google-logo.svg';
import MicrosoftLogo from '../../static/svg/microsoft-logo.svg';

import Loader from '../../components/Loader';
import LoginButton from '../../components/LoginButton';
import * as Api from '../../utils/api';

import './style.css';

class Login extends Component {
  state = {
    isLoading: false,
  };

  componentDidMount() {
    const loggedOutError = localStorage.getItem('loggedOutError');

    if (loggedOutError) {
      localStorage.removeItem('loggedOutError');
      NotificationManager.error(loggedOutError);
    }

    this.setState({ isLoading: false });
    localStorage.removeItem('apiToken');
    localStorage.setItem('loggedin', 'false');
  }

  handleClickLogin = loginFunc => {
    // returns the login function for given auth provider
    return () => {
      // call login function
      loginFunc()
        .then(res => {
          window.location.href = res.data.data;
        })
        .catch(
          err => NotificationManager.error(err.message),
          this.setState({ isLoading: false })
        );
      this.setState({ isLoading: true });
    };
  };

  render() {
    const { isLoading } = this.state;

    return (
      <>
        {isLoading && <Loader />}
        <div className="login-main-container">
          <NotificationContainer />
          <img src={EbitLogo} className="login-logo" width={250} />

          <div className="login-main">
            <h2 className="login-welcome">Welcome</h2>
            <div className="login-with-section">
              <LoginButton
                onClick={this.handleClickLogin(Api.loginGoogle)}
                name="Google"
                image={GoogleLogo}
              />
              <LoginButton
                onClick={this.handleClickLogin(Api.loginMicrosoft)}
                name="Microsoft"
                image={MicrosoftLogo}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
