import React from 'react';
import SmallLogo from '../../static/svg/small-logo.svg';
import PropTypes from 'prop-types';
import { errors } from './constants';
import './style.css';

class Error extends React.Component {
  static get propTypes() {
    return {
      errorCode: PropTypes.any,
    };
  }

  render() {
    const { errorCode } = this.props;

    return (
      <div className="error">
        <div className="error-container">
          <div className="small-logo">
            <img src={SmallLogo} alt="" />
          </div>
          <div className="error-code">{errors[errorCode].title}</div>
          <div className="error-text">{errors[errorCode].text}</div>
          <div className="home-button">
            <button onClick={() => (window.location = '/login')}>Back</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Error;
